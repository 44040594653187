import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "p-4 border-b dark:border-gray-600"
}
const _hoisted_2 = { class: "dark:text-gray-300" }
const _hoisted_3 = { class: "p-2" }

import { computed, nextTick, ref, watch } from 'vue';

import ECharts from 'vue-echarts';
import echarts from 'echarts';

import useTokens from '@/composables/useTokens';
import useUrls from '@/composables/useUrls';
import usePoolCreation from '@/composables/pools/usePoolCreation';
import useBreakpoints from '@/composables/useBreakpoints';

import { prominent } from 'color.js';
import useDarkMode from '@/composables/useDarkMode';
import useTailwind from '@/composables/useTailwind';
import useNumbers, { FNumFormats } from '@/composables/useNumbers';

/**
 * CONSTANTS
 */

export default _defineComponent({
  setup(__props) {

const manualColorMap = {
  ETH: '#627EEA',
  WETH: '#627EEA',
  WBTC: '#F7931A',
  BAL: '#00265A'
};

/** STATE */
const colors = ref<(string | null)[]>([]);
const chartInstance = ref<echarts.ECharts>();

/**
 * COMPOSABLES
 */
const { tokens } = useTokens();
const {
  seedTokens,
  updateTokenColors,
  totalLiquidity,
  tokensList,
  hasRestoredFromSavedState
} = usePoolCreation();
const { upToLargeBreakpoint } = useBreakpoints();
const { darkMode } = useDarkMode();
const { fNum2 } = useNumbers();
const tailwind = useTailwind();
const { resolve } = useUrls();

/**
 * COMPUTED
 */
const chartConfig = computed(() => {
  const validTokens = tokensList.value.filter(t => t !== '');
  if (colors.value.length !== validTokens.length) return;
  return {
    tooltip: {
      show: true,
      borderRadius: 50,
      confine: true
    },
    legend: {
      show: true,
      type: 'scroll',
      icon: 'circle',
      formatter: name => {
        return `${tokens.value[name]?.symbol || 'Unallocated'}`;
      },
      selectedMode: false,
      top: 'bottom',
      textStyle: {
        color: darkMode.value
          ? tailwind.theme.colors.gray['300']
          : tailwind.theme.colors.gray['850']
      }
    },
    series: [
      {
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        stillShowZeroSum: true,
        showEmptyCircle: true,
        itemStyle: {
          borderColor: darkMode.value
            ? tailwind.theme.colors.gray['850']
            : '#fff',
          borderWidth: 5,
          borderCap: 'butt',
          borderJoin: 'round'
        },
        label: {
          show: false,
          position: 'center'
        },
        labelLine: {
          show: false
        },
        top: -20,
        colors: colors.value,
        data: [
          ...seedTokens.value
            // .filter(t => t.tokenAddress !== '')
            .map((t, i) => {
              const tokenLogoURI = resolve(
                tokens.value[t.tokenAddress]?.logoURI || ''
              );
              return {
                name: t.tokenAddress,
                value: t.weight,
                tooltip: {
                  show: false,
                  borderWidth: '0'
                },
                emphasis: {
                  label: {
                    show: true,
                    formatter: () => ' ',
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    backgroundColor: {
                      image: tokenLogoURI
                    },
                    width: 48,
                    height: 48,
                    borderRadius: 48,
                    overflow: 'hidden'
                  }
                },
                itemStyle: {
                  color:
                    t.tokenAddress === ''
                      ? tailwind.theme.colors.gray[`${i + 1}00`]
                      : colors.value[i]
                }
              };
            })
        ]
      }
    ]
  };
});

/**
 * WATCHERS
 */
watch(
  [seedTokens, hasRestoredFromSavedState],
  async () => {
    const colors = await calculateColors();
    await nextTick();
    updateTokenColors(colors as string[]);
  },
  { deep: true, immediate: true }
);

/**
 * FUNCTIONS
 */
async function calculateColors() {
  const colorPromises = seedTokens.value
    .filter(t => t.tokenAddress !== '')
    .map(async t => {
      try {
        const tokenLogoURI = resolve(
          tokens.value[t.tokenAddress].logoURI || ''
        );
        const color = await prominent(tokenLogoURI, {
          amount: 2,
          format: 'hex'
        });
        if (manualColorMap[tokens.value[t.tokenAddress].symbol]) {
          return manualColorMap[tokens.value[t.tokenAddress].symbol];
        }
        if (color[0] === '#ffffff' || color[0] === '#000000')
          return color[1] as string;
        return color[0] as string;
      } catch {
        return null;
      }
    });
  const _colors = await Promise.all(colorPromises);
  colors.value = _colors;
  return _colors;
}

return (_ctx: any,_cache: any) => {
  const _component_BalTooltip = _resolveComponent("BalTooltip")!
  const _component_BalStack = _resolveComponent("BalStack")!
  const _component_BalCard = _resolveComponent("BalCard")!

  return (_openBlock(), _createBlock(_component_BalCard, {
    noPad: "",
    shadow: "none"
  }, {
    default: _withCtx(() => [
      (!_unref(upToLargeBreakpoint))
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("h6", _hoisted_2, _toDisplayString(_ctx.$t('createAPool.poolSummary')), 1)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_unref(ECharts), {
          ref: chartInstance,
          class: "w-full h-56",
          option: _unref(chartConfig),
          autoresize: ""
        }, null, 8, ["option"])
      ]),
      _createVNode(_component_BalStack, {
        class: "mb-2",
        vertical: "",
        spacing: "none",
        align: "center"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BalStack, {
            horizontal: "",
            spacing: "xs"
          }, {
            default: _withCtx(() => [
              _createElementVNode("h6", null, _toDisplayString(_ctx.$t('inYourWallet')), 1),
              _createVNode(_component_BalTooltip, {
                iconSize: "sm",
                class: "mt-1"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('createAPool.maxLiquidityTooltip')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createElementVNode("span", null, _toDisplayString(_unref(fNum2)(_unref(totalLiquidity).toString(), _unref(FNumFormats).fiat)), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})