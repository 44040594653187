import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "p-4 dark:border-gray-600 border-b"
}
const _hoisted_2 = { class: "dark:text-gray-300" }
const _hoisted_3 = { class: "p-2 px-4" }
const _hoisted_4 = { class: "-mr-1" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "w-4/5 break-words" }
const _hoisted_7 = { class: "w-full justify-end" }
const _hoisted_8 = { class: "-mr-1" }
const _hoisted_9 = { class: "text-left" }

import usePoolCreation from '@/composables/pools/usePoolCreation';
import useBreakpoints from '@/composables/useBreakpoints';
import useNumbers, { FNumFormats } from '@/composables/useNumbers';
import useTokens from '@/composables/useTokens';

import { computed } from 'vue';

type Props = {
  toggleUnknownPriceModal?: () => void;
};


export default _defineComponent({
  props: {
    toggleUnknownPriceModal: { type: Function, required: false }
  } as unknown as undefined,
  setup(__props: {
  toggleUnknownPriceModal?: () => void;
}) {



/**
 * COMPOSABLES
 */
const { upToLargeBreakpoint } = useBreakpoints();
const { tokensList } = usePoolCreation();
const { tokens, priceFor, injectedPrices } = useTokens();
const { fNum2 } = useNumbers();

/**
 * COMPUTED
 */
const validTokens = computed(() => tokensList.value.filter(t => t !== ''));
const knownTokens = computed(() =>
  validTokens.value.filter(
    token => priceFor(token) !== 0 && !injectedPrices.value[token]
  )
);
const unknownTokens = computed(() =>
  validTokens.value.filter(
    token => priceFor(token) === 0 || injectedPrices.value[token]
  )
);
const hasUnknownPrice = computed(() =>
  validTokens.value.some(token => priceFor(token) === 0)
);

return (_ctx: any,_cache: any) => {
  const _component_BalTooltip = _resolveComponent("BalTooltip")!
  const _component_BalStack = _resolveComponent("BalStack")!
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalCard = _resolveComponent("BalCard")!

  return (_openBlock(), _createBlock(_component_BalCard, {
    noPad: "",
    shadow: "none",
    noBorder: _unref(upToLargeBreakpoint)
  }, {
    default: _withCtx(() => [
      (!_unref(upToLargeBreakpoint))
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_BalStack, {
              horizontal: "",
              spacing: "sm",
              align: "center"
            }, {
              default: _withCtx(() => [
                _createElementVNode("h6", _hoisted_2, _toDisplayString(_ctx.$t('tokenPrices')), 1),
                _createVNode(_component_BalTooltip, {
                  class: "mt-1",
                  text: _ctx.$t('correctTokenPricing')
                }, null, 8, ["text"])
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_BalStack, {
          vertical: "",
          isDynamic: "",
          spacing: "sm"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(knownTokens), (token) => {
              return (_openBlock(), _createBlock(_component_BalStack, {
                key: `tokenPrice-known-${token}`,
                justify: "between",
                align: "center"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(_unref(tokens)[token]?.symbol), 1),
                  _createVNode(_component_BalStack, {
                    horizontal: "",
                    justify: "center"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", null, [
                        _createElementVNode("div", _hoisted_4, [
                          _createElementVNode("span", null, _toDisplayString(_unref(fNum2)(_unref(priceFor)(token), _unref(FNumFormats).fiat)), 1)
                        ])
                      ]),
                      _createElementVNode("img", {
                        class: "h-5",
                        src: require('@/assets/images/icons/coingecko.svg')
                      }, null, 8, _hoisted_5)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        }),
        _createVNode(_component_BalStack, {
          vertical: "",
          isDynamic: "",
          spacing: "xs",
          class: _normalizeClass({ 'mt-1': _unref(knownTokens).length })
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(unknownTokens), (token) => {
              return (_openBlock(), _createElementBlock("button", {
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (__props.toggleUnknownPriceModal && __props.toggleUnknownPriceModal(...args))),
                class: _normalizeClass([
            'mt-1',
            {
              'text-red-500 hover:text-red-700': _unref(hasUnknownPrice),
              'hover:text-blue-500': !_unref(hasUnknownPrice)
            }
          ]),
                key: `tokenPrice-unknown-${token}`
              }, [
                _createVNode(_component_BalStack, {
                  horizontal: "",
                  isDynamic: "",
                  justify: "between"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", {
                      class: _normalizeClass([
                'w-1/2 text-left',
                { 'font-medium': _unref(injectedPrices)[token] === undefined }
              ])
                    }, _toDisplayString(_unref(tokens)[token]?.symbol), 3),
                    (_unref(injectedPrices)[token] !== undefined)
                      ? (_openBlock(), _createBlock(_component_BalStack, {
                          key: 0,
                          horizontal: "",
                          align: "center",
                          class: "w-1/2 text-right"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", _hoisted_6, _toDisplayString(_unref(fNum2)(_unref(injectedPrices)[token], _unref(FNumFormats).fiat)), 1),
                            _createVNode(_component_BalIcon, {
                              size: "sm",
                              name: "edit",
                              class: "mr-px"
                            })
                          ]),
                          _: 2
                        }, 1024))
                      : (_openBlock(), _createBlock(_component_BalStack, {
                          key: 1,
                          spacing: "none",
                          horizontal: "",
                          align: "center",
                          class: "w-3/4"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_7, [
                              _createElementVNode("div", _hoisted_8, [
                                _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('enterAPrice')), 1)
                              ])
                            ]),
                            _createVNode(_component_BalIcon, { name: "alert-circle" })
                          ]),
                          _: 1
                        }))
                  ]),
                  _: 2
                }, 1024)
              ], 2))
            }), 128))
          ]),
          _: 1
        }, 8, ["class"])
      ])
    ]),
    _: 1
  }, 8, ["noBorder"]))
}
}

})