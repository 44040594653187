import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bal-icon inline-block" }
const _hoisted_2 = ["data-feather", "width", "height", "fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("i", {
      "data-feather": _ctx.name,
      width: _ctx.iconSize,
      height: _ctx.iconSize,
      fill: _ctx.fill
    }, null, 8, _hoisted_2)
  ]))
}