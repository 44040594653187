import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-0e607598")
const _hoisted_1 = { class: "message relative px-2 py-3" }
const _hoisted_2 = { class: "ml-12" }
const _hoisted_3 = ["textContent"]
const _hoisted_4 = ["innerHTML"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalLink = _resolveComponent("BalLink")!

  return (_ctx.isActive())
    ? (_openBlock(), _createBlock(_component_BalLink, {
        key: 0,
        href: _ctx.EXTERNAL_LINKS.Balancer.BalForGas,
        external: "",
        class: "block hover:no-underline"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("h6", {
                textContent: _toDisplayString(_ctx.$t('highGasFees')),
                class: "relative text-sm"
              }, null, 8, _hoisted_3),
              _createElementVNode("div", {
                class: "relative text-sm text-gray-500",
                innerHTML: _ctx.text
              }, null, 8, _hoisted_4)
            ])
          ])
        ]),
        _: 1
      }, 8, ["href"]))
    : _createCommentVNode("", true)
}