
import { PropType, reactive, toRefs } from 'vue';
import useNumbers from '@/composables/useNumbers';
import { TokenList } from '@/types/TokenList';
import useUrls from '@/composables/useUrls';

export default {
  name: 'TokenListsListItem',

  props: {
    tokenlist: {
      type: Object as PropType<TokenList>
    },
    uri: {
      type: String,
      required: true
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },

  emits: ['toggle'],

  setup(props) {
    /**
     * COMPOSABLES
     */
    const { fNum2 } = useNumbers();
    const { resolve } = useUrls();

    /**
     * STATE
     */
    const state = reactive({
      notBalancer: props.tokenlist?.name !== 'Balancer',
      listUrl: resolve(props.uri)
    });

    return {
      ...toRefs(state),
      fNum2
    };
  }
};
