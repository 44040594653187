import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MoonIcon = _resolveComponent("MoonIcon")!
  const _component_SunIcon = _resolveComponent("SunIcon")!
  const _component_BalBtn = _resolveComponent("BalBtn")!

  return (_openBlock(), _createBlock(_component_BalBtn, {
    color: "white",
    size: _ctx.upToLargeBreakpoint ? 'md' : 'sm',
    onClick: _ctx.toggleDarkMode
  }, {
    default: _withCtx(() => [
      (_ctx.darkMode)
        ? (_openBlock(), _createBlock(_component_MoonIcon, { key: 0 }))
        : (_openBlock(), _createBlock(_component_SunIcon, { key: 1 }))
    ]),
    _: 1
  }, 8, ["size", "onClick"]))
}