import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { flatten } from 'lodash';

import usePoolActivitiesQuery from '@/composables/queries/usePoolActivitiesQuery';
import usePoolUserActivitiesQuery from '@/composables/queries/usePoolUserActivitiesQuery';

import { FullPool } from '@/services/balancer/subgraph/types';

import { PoolTransactionsTab } from '../types';

import Table from './Table.vue';

/**
 * TYPES
 */
type Props = {
  pool: FullPool;
  loading: boolean;
  poolActivityType: PoolTransactionsTab;
};

/**
 * PROPS
 */

export default _defineComponent({
  props: {
    pool: { type: null, required: true },
    loading: { type: Boolean, required: true, default: false },
    poolActivityType: { type: null, required: true, default: PoolTransactionsTab.ALL_ACTIVITY }
  } as unknown as undefined,
  setup(__props: {
  pool: FullPool;
  loading: boolean;
  poolActivityType: PoolTransactionsTab;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const route = useRoute();

/**
 * STATE
 */

const id = route.params.id as string;
/**
 * QUERIES
 */

const poolActivitiesQuery =
  props.poolActivityType === PoolTransactionsTab.ALL_ACTIVITY
    ? usePoolActivitiesQuery(id)
    : usePoolUserActivitiesQuery(id);

/**
 * COMPUTED
 */
const poolActivities = computed(() =>
  poolActivitiesQuery.data.value
    ? flatten(
        poolActivitiesQuery.data.value.pages.map(page => page.poolActivities)
      )
    : []
);
const isLoadingPoolActivities = computed(
  () => poolActivitiesQuery.isLoading.value
);
const poolActivitiesHasNextPage = computed(
  () => poolActivitiesQuery.hasNextPage?.value
);
const poolActivitiesIsFetchingNextPage = computed(
  () => poolActivitiesQuery.isFetchingNextPage?.value
);

/**
 * METHODS
 */
function loadMorePoolActivities() {
  poolActivitiesQuery.fetchNextPage.value();
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Table, {
    tokens: __props.pool ? __props.pool.tokensList : [],
    "pool-activities": _unref(poolActivities),
    "is-loading": __props.loading || _unref(isLoadingPoolActivities),
    "is-loading-more": _unref(poolActivitiesIsFetchingNextPage),
    "is-paginated": _unref(poolActivitiesHasNextPage),
    onLoadMore: loadMorePoolActivities,
    "no-results-label": 
      __props.poolActivityType === _unref(PoolTransactionsTab).ALL_ACTIVITY
        ? _ctx.$t('poolTransactions.noResults.allInvestments')
        : _ctx.$t('poolTransactions.noResults.myInvestments')
    
  }, null, 8, ["tokens", "pool-activities", "is-loading", "is-loading-more", "is-paginated", "no-results-label"]))
}
}

})