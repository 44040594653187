import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "p-4 w-full border-b dark:border-gray-900" }
const _hoisted_2 = { class: "-mt-2 p-4" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "flex items-start justify-between" }
const _hoisted_5 = { class: "flex justify-between" }
const _hoisted_6 = { class: "lowercase" }
const _hoisted_7 = { class: "pt-4 flex justify-between font-medium" }
const _hoisted_8 = { class: "text-right" }

import { computed, toRef } from 'vue';
import { bnum } from '@/lib/utils';
import { FullPool } from '@/services/balancer/subgraph/types';
// Composables
import useNumbers, { FNumFormats } from '@/composables/useNumbers';
import useTokens from '@/composables/useTokens';
// Components
import AssetRow from './components/AssetRow.vue';
import { useRoute } from 'vue-router';
import { usePool } from '@/composables/usePool';

/**
 * TYPES
 */
type Props = {
  pool: FullPool;
  useNativeAsset: boolean;
  hideHeader?: boolean;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    pool: { type: null, required: true },
    useNativeAsset: { type: Boolean, required: true },
    hideHeader: { type: Boolean, required: false, default: false }
  } as unknown as undefined,
  emits: ["update:useNativeAsset"] as unknown as undefined,
  setup(__props: {
  pool: FullPool;
  useNativeAsset: boolean;
  hideHeader?: boolean;
}, { emit }: { emit: ({
  (e: 'update:useNativeAsset', value: boolean): void;
}), expose: any, slots: any, attrs: any }) {

const props = __props




/**
 * COMPOSABLES
 */
const { isWethPool, isStablePhantomPool } = usePool(toRef(props, 'pool'));
const { balanceFor, nativeAsset, wrappedNativeAsset } = useTokens();
const { fNum2, toFiat } = useNumbers();
const route = useRoute();

/**
 * COMPUTED
 */
const pageContext = computed(() => route.name);

const tokenAddresses = computed((): string[] => {
  if (isStablePhantomPool.value) {
    return props.pool.mainTokens || [];
  }

  return props.pool.tokenAddresses;
});

const tokensForTotal = computed((): string[] => {
  if (pageContext.value === 'invest' && props.useNativeAsset) {
    return tokenAddresses.value.map(address => {
      if (address === wrappedNativeAsset.value.address)
        return nativeAsset.address;
      return address;
    });
  } else if (pageContext.value === 'withdraw' && isWethPool.value) {
    return [nativeAsset.address, ...tokenAddresses.value];
  }

  return tokenAddresses.value;
});

const fiatTotal = computed(() => {
  const fiatValue = tokensForTotal.value
    .map(address => {
      if (pageContext.value === 'invest') {
        if (address === nativeAsset.address && !props.useNativeAsset)
          return '0';
        if (
          address === wrappedNativeAsset.value.address &&
          props.useNativeAsset
        )
          return '0';
      }

      const tokenBalance = balanceFor(address);
      return toFiat(tokenBalance, address);
    })
    .reduce((total, value) =>
      bnum(total)
        .plus(value)
        .toString()
    );

  return fNum2(fiatValue, FNumFormats.fiat);
});

/**
 * METHODS
 */
function isSelectedNativeAsset(address: string): boolean {
  if (pageContext.value === 'withdraw') return true;
  if (props.useNativeAsset && address === nativeAsset.address) return true;

  return !props.useNativeAsset && address === wrappedNativeAsset.value.address;
}

return (_ctx: any,_cache: any) => {
  const _component_BalTooltip = _resolveComponent("BalTooltip")!
  const _component_BalBreakdown = _resolveComponent("BalBreakdown")!
  const _component_BalCard = _resolveComponent("BalCard")!

  return (_openBlock(), _createBlock(_component_BalCard, {
    shadow: "none",
    noPad: ""
  }, _createSlots({
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tokenAddresses), (address) => {
          return (_openBlock(), _createElementBlock("div", {
            key: address,
            class: "py-2"
          }, [
            (address === _unref(wrappedNativeAsset).address)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_BalBreakdown, {
                      items: [_unref(nativeAsset), _unref(wrappedNativeAsset)],
                      class: "w-full",
                      size: "lg"
                    }, {
                      item: _withCtx(({ item: asset }) => [
                        _createVNode(AssetRow, {
                          address: asset.address,
                          selected: isSelectedNativeAsset(asset.address),
                          class: _normalizeClass([{ 'cursor-pointer': _unref(pageContext) === 'invest' }]),
                          onClick: ($event: any) => (
                    emit(
                      'update:useNativeAsset',
                      asset.address === _unref(nativeAsset).address
                    )
                  )
                        }, null, 8, ["address", "selected", "class", "onClick"])
                      ]),
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_5, [
                          _createElementVNode("span", null, [
                            _createTextVNode(_toDisplayString(_unref(nativeAsset).name) + " ", 1),
                            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('tokens')), 1)
                          ]),
                          (_unref(pageContext) === 'invest')
                            ? (_openBlock(), _createBlock(_component_BalTooltip, {
                                key: 0,
                                text: 
                    _ctx.$t(
                      'poolTransfer.myWalletTokensCard.tooltips.nativeAssetSelection',
                      [_unref(nativeAsset).symbol, _unref(wrappedNativeAsset).symbol]
                    )
                  
                              }, null, 8, ["text"]))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      _: 2
                    }, 1032, ["items"])
                  ])
                ]))
              : (_openBlock(), _createBlock(AssetRow, {
                  key: 1,
                  address: address,
                  selected: true
                }, null, 8, ["address"]))
          ]))
        }), 128)),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('total')), 1),
          _createElementVNode("span", _hoisted_8, _toDisplayString(_unref(fiatTotal)), 1)
        ])
      ])
    ]),
    _: 2
  }, [
    (!__props.hideHeader)
      ? {
          name: "header",
          fn: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("h6", null, _toDisplayString(_ctx.$t('poolTransfer.myWalletTokensCard.title')), 1)
            ])
          ])
        }
      : undefined
  ]), 1024))
}
}

})