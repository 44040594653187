import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createElementVNode as _createElementVNode, Teleport as _Teleport, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-1a29be5f")
const _hoisted_1 = { class: "mt-8 p-4 sm:p-0 lg:p-0" }
const _hoisted_2 = { class: "graph-modal" }
_popScopeId()

import { computed, onMounted, ref } from 'vue';
import { ENABLE_LEGACY_TRADE_INTERFACE } from '@/composables/trade/constants';
// Types
import { TradeInterface } from '@/store/modules/app';
// Composables
import { useStore } from 'vuex';
import useBreakpoints from '@/composables/useBreakpoints';
import useTokenLists from '@/composables/useTokenLists';
import usePoolFilters from '@/composables/pools/usePoolFilters';
// Components
import MyWallet from '@/components/cards/MyWallet/MyWallet.vue';
import TrendingPairs from '@/components/cards/TrendingPairs/TrendingPairs.vue';
import PairPriceGraph from '@/components/cards/PairPriceGraph/PairPriceGraph.vue';
import TradeCard from '@/components/cards/TradeCard/TradeCard.vue';
import TradeCardGP from '@/components/cards/TradeCardGP/TradeCardGP.vue';
import Col3Layout from '@/components/layouts/Col3Layout.vue';

/**
 * STATE
 */

export default _defineComponent({
  setup(__props) {

const showPriceGraphModal = ref(false);

/**
 * COMPOSABLES
 */
const store = useStore();
const { loadingTokenLists } = useTokenLists();
const { setSelectedTokens } = usePoolFilters();
const { upToLargeBreakpoint } = useBreakpoints();

/**
 * COMPUTED
 */
const appLoading = computed(() => store.state.app.loading);
const tradeInterface = computed(() => store.state.app.tradeInterface);

/**
 * METHODS
 */
function onPriceGraphModalClose() {
  showPriceGraphModal.value = false;
}

function togglePairPriceGraphModal() {
  showPriceGraphModal.value = !showPriceGraphModal.value;
}

/**
 * CALLBACKS
 */
onMounted(() => {
  // selectedPoolTokens are only persisted between the Home/Pool pages
  setSelectedTokens([]);
});

return (_ctx: any,_cache: any) => {
  const _component_BalLoadingBlock = _resolveComponent("BalLoadingBlock")!
  const _component_BalAccordion = _resolveComponent("BalAccordion")!
  const _component_BalModal = _resolveComponent("BalModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(Col3Layout, {
      offsetGutters: "",
      mobileHideGutters: "",
      class: "mt-8"
    }, {
      gutterLeft: _withCtx(() => [
        _createVNode(MyWallet),
        _createVNode(TrendingPairs, { class: "mt-4" })
      ]),
      gutterRight: _withCtx(() => [
        _createVNode(PairPriceGraph, { toggleModal: togglePairPriceGraphModal })
      ]),
      default: _withCtx(() => [
        (_unref(appLoading) || _unref(loadingTokenLists))
          ? (_openBlock(), _createBlock(_component_BalLoadingBlock, {
              key: 0,
              class: "h-96"
            }))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              (_unref(ENABLE_LEGACY_TRADE_INTERFACE))
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    (_unref(tradeInterface) === _unref(TradeInterface).BALANCER)
                      ? (_openBlock(), _createBlock(TradeCard, { key: 0 }))
                      : (_unref(tradeInterface) === _unref(TradeInterface).GNOSIS)
                        ? (_openBlock(), _createBlock(TradeCardGP, { key: 1 }))
                        : _createCommentVNode("", true)
                  ], 64))
                : (_openBlock(), _createBlock(TradeCardGP, { key: 1 }))
            ], 64)),
        _createElementVNode("div", _hoisted_1, [
          (_unref(upToLargeBreakpoint))
            ? (_openBlock(), _createBlock(_component_BalAccordion, {
                key: 0,
                class: "w-full",
                sections: [
          { title: 'My wallet', id: 'my-wallet' },
          { title: 'Trending pairs', id: 'trending-pairs' },
          { title: 'Price chart', id: 'price-chart' }
        ]
              }, {
                "my-wallet": _withCtx(() => [
                  _createVNode(MyWallet)
                ]),
                "trending-pairs": _withCtx(() => [
                  _createVNode(TrendingPairs)
                ]),
                "price-chart": _withCtx(() => [
                  _createVNode(PairPriceGraph, { toggleModal: togglePairPriceGraphModal })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }),
    (_openBlock(), _createBlock(_Teleport, { to: "#modal" }, [
      _createVNode(_component_BalModal, {
        show: showPriceGraphModal.value,
        onClose: onPriceGraphModalClose
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(PairPriceGraph, {
              toggleModal: togglePairPriceGraphModal,
              isModal: "",
              onCloseModal: onPriceGraphModalClose
            })
          ])
        ]),
        _: 1
      }, 8, ["show"])
    ]))
  ], 64))
}
}

})