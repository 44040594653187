import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-f9617fea")
const _hoisted_1 = { class: "trending-pairs" }
const _hoisted_2 = {
  key: 0,
  class: "flex justify-between p-3 lg:border-b dark:border-gray-700"
}
const _hoisted_3 = { class: "px-1 lg:p-3 flex flex-wrap" }
const _hoisted_4 = ["onClick"]
_popScopeId()

import { useTradeState } from '@/composables/trade/useTradeState';
import useBreakpoints from '@/composables/useBreakpoints';
import QUERY_KEYS from '@/constants/queryKeys';
import { balancerSubgraphService } from '@/services/balancer/subgraph/balancer-subgraph.service';
import useWeb3 from '@/services/web3/useWeb3';
import { getAddress } from '@ethersproject/address';
import { getUnixTime } from 'date-fns';
import { computed } from 'vue';
import { useQuery } from 'vue-query';

type TrendingPair = {
  symbol: string;
  address: string;
};


export default _defineComponent({
  setup(__props) {

const { setTokenOutAddress, setTokenInAddress } = useTradeState();
const { chainId: userNetworkId, appNetworkConfig } = useWeb3();
const { upToLargeBreakpoint } = useBreakpoints();

const getTrendingTradePairs = async () => {
  return await balancerSubgraphService.tradePairSnapshots.get({
    orderBy: 'totalSwapVolume',
    orderDirection: 'desc',
    where: {
      timestamp_gte: getUnixTime(new Date().setUTCHours(0, 0, 0, 0))
    },
    first: 5
  });
};

const { data: tradePairSnapshots } = useQuery(
  QUERY_KEYS.Tokens.TrendingPairs(userNetworkId),
  () => getTrendingTradePairs()
);

function formatToken({ address, symbol }: { address: string; symbol: string }) {
  const formatted = getAddress(address);

  if (formatted === appNetworkConfig.addresses.weth) {
    return {
      address: appNetworkConfig.nativeAsset.address,
      symbol: appNetworkConfig.nativeAsset.symbol
    };
  }

  return {
    address: formatted,
    symbol
  };
}

const trendingPairs = computed(() => {
  return (tradePairSnapshots.value || []).map(pairSnapshot => [
    formatToken(pairSnapshot.pair.token0),
    formatToken(pairSnapshot.pair.token1)
  ]);
});

const setTradePair = (pair: TrendingPair[]) => {
  setTokenInAddress(pair[0].address);
  setTokenOutAddress(pair[1].address);
};

return (_ctx: any,_cache: any) => {
  const _component_BalCard = _resolveComponent("BalCard")!

  return (_openBlock(), _createBlock(_component_BalCard, {
    square: _unref(upToLargeBreakpoint),
    noPad: "",
    growContent: "",
    noBorder: _unref(upToLargeBreakpoint),
    shadow: "none"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (!_unref(upToLargeBreakpoint))
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("h6", null, _toDisplayString(_ctx.$t('trendingPairs')), 1)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(trendingPairs), (pair, i) => {
            return (_openBlock(), _createElementBlock("button", {
              class: "py-1 px-2 bg-transparent hover:bg-blue-500 hover:text-white text-sm rounded-lg lg:shadow my-2 mr-2 font-medium lg:font-normal",
              key: `trendingPair-${i}`,
              onClick: ($event: any) => (setTradePair(pair))
            }, _toDisplayString(pair[0].symbol) + "/" + _toDisplayString(pair[1].symbol), 9, _hoisted_4))
          }), 128))
        ])
      ])
    ]),
    _: 1
  }, 8, ["square", "noBorder"]))
}
}

})