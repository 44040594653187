
import { computed, defineComponent, PropType, ref } from 'vue';
import SelectTokenModal from '@/components/modals/SelectTokenModal/SelectTokenModal.vue';
import { pick, take } from 'lodash';
import { NATIVE_ASSET_ADDRESS, TOKENS } from '@/constants/tokens';
import { getAddress } from '@ethersproject/address';
import useWeb3 from '@/services/web3/useWeb3';
import useTokens from '@/composables/useTokens';

export default defineComponent({
  name: 'TokenSearchInput',

  components: {
    SelectTokenModal
  },

  emits: ['add', 'remove'],

  props: {
    modelValue: { type: Array as PropType<string[]>, default: () => [] },
    loading: { type: Boolean, default: true }
  },

  setup(props, { emit }) {
    /**
     * STATE
     */
    const selectTokenModal = ref(false);

    /**
     * COMPOSABLES
     */
    const { tokens, balances, dynamicDataLoading } = useTokens();
    const { account, appNetworkConfig } = useWeb3();

    /**
     * COMPUTED
     */
    // sorted by biggest bag balance, limited to biggest 5
    const sortedBalances = computed(() => {
      const addressesWithBalance = Object.entries(balances.value)
        .filter(balance => balance[1] !== '0.0')
        .map(balance => balance[0]);
      const tokensWithBalance = Object.values(
        pick(tokens.value, addressesWithBalance)
      );

      return take(tokensWithBalance, 6);
    });

    const hasNoBalances = computed(() => !sortedBalances.value.length);

    const whiteListedTokens = computed(() =>
      Object.values(tokens.value)
        .filter(token => TOKENS.Popular.Symbols.includes(token.symbol))
        .filter(token => !props.modelValue.includes(token.address))
    );

    /**
     * METHODS
     */
    function addToken(token: string) {
      let _token = token;
      // special case for ETH where we want it to filter as WETH regardless
      // as ETH is the native asset
      if (getAddress(token) === NATIVE_ASSET_ADDRESS) {
        _token = appNetworkConfig.addresses.weth;
      }
      // const newSelected = [...props.modelValue, _token];
      emit('add', _token);
    }

    function onClick() {
      if (!props.loading) selectTokenModal.value = true;
    }

    return {
      // state
      selectTokenModal,
      // computed
      tokens,
      dynamicDataLoading,
      balances,
      sortedBalances,
      account,
      hasNoBalances,
      whiteListedTokens,
      // methods
      addToken,
      onClick
    };
  }
});
