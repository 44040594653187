import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-4" }
const _hoisted_2 = { class: "text-sm text-gray-500 font-medium mb-2" }
const _hoisted_3 = { class: "text-xl font-medium truncate flex items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalLoadingBlock = _resolveComponent("BalLoadingBlock")!
  const _component_LiquidityAPRTooltip = _resolveComponent("LiquidityAPRTooltip")!
  const _component_BalCard = _resolveComponent("BalCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, _renderList(4, (n) => {
          return _createVNode(_component_BalLoadingBlock, {
            key: n,
            class: "h-24"
          })
        }), 64))
      : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.stats, (stat, i) => {
          return (_openBlock(), _createBlock(_component_BalCard, { key: i }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, _toDisplayString(stat.label), 1),
              _createElementVNode("div", _hoisted_3, [
                _createTextVNode(_toDisplayString(stat.value) + " ", 1),
                (stat.id === 'apr')
                  ? (_openBlock(), _createBlock(_component_LiquidityAPRTooltip, {
                      key: 0,
                      pool: _ctx.pool
                    }, null, 8, ["pool"]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 2
          }, 1024))
        }), 128))
  ]))
}