
import BalCard from '../BalCard/BalCard.vue';
import AnimatePresence from '../../animate/AnimatePresence.vue';
import { defineComponent, ref, toRefs, watch } from 'vue';

export default defineComponent({
  name: 'BalModal',

  components: {
    BalCard,
    AnimatePresence
  },

  props: {
    show: { type: Boolean, default: false },
    title: { type: String, default: '' },
    noPad: { type: Boolean, default: false },
    noContentPad: { type: Boolean, default: false },
    fireworks: { type: Boolean, default: false }
  },

  setup(props) {
    const { show } = toRefs(props);
    const showContent = ref(show.value);

    // Watchers
    watch(show, newVal => {
      showContent.value = newVal;
    });

    // Methods
    function hide(): void {
      showContent.value = false;
    }

    return {
      showContent,
      hide
    };
  }
});
