<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="9"
      cy="9"
      r="8"
      stroke="currentColor"
      stroke-opacity="0.5"
      stroke-width="1.5"
    />
    <path
      d="M9 17C4.58172 17 1 13.4183 1 9"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="bevel"
      stroke-dasharray="387 10"
    />
  </svg>
</template>
