import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { flatten } from 'lodash';

import usePoolSwapsQuery from '@/composables/queries/usePoolSwapsQuery';
import useUserPoolSwapsQuery from '@/composables/queries/useUserPoolSwapsQuery';

import { FullPool } from '@/services/balancer/subgraph/types';

import { PoolTransactionsTab } from '../types';

import Table from './Table.vue';

/**
 * TYPES
 */
type Props = {
  pool: FullPool;
  loading: boolean;
  poolActivityType: PoolTransactionsTab;
};

/**
 * PROPS
 */

export default _defineComponent({
  props: {
    pool: { type: null, required: true },
    loading: { type: Boolean, required: true, default: false },
    poolActivityType: { type: null, required: true, default: PoolTransactionsTab.ALL_ACTIVITY }
  } as unknown as undefined,
  setup(__props: {
  pool: FullPool;
  loading: boolean;
  poolActivityType: PoolTransactionsTab;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const route = useRoute();

/**
 * STATE
 */

const id = route.params.id as string;
/**
 * QUERIES
 */

const poolSwapsQuery =
  props.poolActivityType === PoolTransactionsTab.ALL_ACTIVITY
    ? usePoolSwapsQuery(id)
    : useUserPoolSwapsQuery(id);

/**
 * COMPUTED
 */
const poolSwaps = computed(() =>
  poolSwapsQuery.data.value
    ? flatten(poolSwapsQuery.data.value.pages.map(page => page.poolSwaps))
    : []
);
const isLoadingPoolSwaps = computed(() => poolSwapsQuery.isLoading.value);
const poolSwapsHasNextPage = computed(() => poolSwapsQuery.hasNextPage?.value);
const poolSwapsIsFetchingNextPage = computed(
  () => poolSwapsQuery.isFetchingNextPage?.value
);

/**
 * METHODS
 */
function loadMorePoolSwaps() {
  poolSwapsQuery.fetchNextPage.value();
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Table, {
    tokens: __props.pool ? __props.pool.tokensList : [],
    "pool-swaps": _unref(poolSwaps),
    pool: __props.pool,
    "is-loading": __props.loading || _unref(isLoadingPoolSwaps),
    "is-loading-more": _unref(poolSwapsIsFetchingNextPage),
    "is-paginated": _unref(poolSwapsHasNextPage),
    onLoadMore: loadMorePoolSwaps,
    "no-results-label": 
      __props.poolActivityType === _unref(PoolTransactionsTab).ALL_ACTIVITY
        ? _ctx.$t('poolTransactions.noResults.allTransactions')
        : _ctx.$t('poolTransactions.noResults.myTransactions')
    
  }, null, 8, ["tokens", "pool-swaps", "pool", "is-loading", "is-loading-more", "is-paginated", "no-results-label"]))
}
}

})