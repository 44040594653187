import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-760a3aca")
const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "font-semibold flex items-center" }
const _hoisted_3 = { class: "text-sm text-gray-500 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white transition-colors summary" }
const _hoisted_4 = { class: "failed-reason-tooltip" }
const _hoisted_5 = {
  key: 0,
  class: "mt-1"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalLink = _resolveComponent("BalLink")!
  const _component_SpinnerIcon = _resolveComponent("SpinnerIcon")!
  const _component_CheckIcon = _resolveComponent("CheckIcon")!
  const _component_BalTooltip = _resolveComponent("BalTooltip")!
  const _component_BalBtn = _resolveComponent("BalBtn")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.transactions, (transaction) => {
      return (_openBlock(), _createElementBlock("div", {
        key: transaction.id,
        class: "mb-3"
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_BalLink, {
            href: _ctx.getExplorerLink(transaction.id, transaction.type),
            disabled: 
            _ctx.disablePending && _ctx.isPendingTransactionStatus(transaction.status)
          ,
            external: "",
            noStyle: "",
            class: "group"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createTextVNode(_toDisplayString(_ctx.$t(`transactionAction.${transaction.action}`)) + " ", 1),
                (
                !(
                  _ctx.disablePending &&
                  _ctx.isPendingTransactionStatus(transaction.status)
                )
              )
                  ? (_openBlock(), _createBlock(_component_BalIcon, {
                      key: 0,
                      name: "arrow-up-right",
                      size: "sm",
                      class: "ml-1 text-gray-400 dark:text-gray-600 group-hover:text-pink-500 transition-colors"
                    }))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_3, _toDisplayString(transaction.summary), 1)
            ]),
            _: 2
          }, 1032, ["href", "disabled"]),
          _createElementVNode("div", null, [
            (_ctx.isPendingTransactionStatus(transaction.status))
              ? (_openBlock(), _createBlock(_component_SpinnerIcon, {
                  key: 0,
                  class: "animate-spin text-yellow-500"
                }))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  (_ctx.isSuccessfulTransaction(transaction))
                    ? (_openBlock(), _createBlock(_component_CheckIcon, {
                        key: 0,
                        class: "text-green-500"
                      }))
                    : (_openBlock(), _createBlock(_component_BalTooltip, {
                        key: 1,
                        class: "cursor-default"
                      }, {
                        activator: _withCtx(() => [
                          _createVNode(_component_BalIcon, {
                            name: "alert-circle",
                            class: "text-red-500"
                          })
                        ]),
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t(`transactionAction.${transaction.action}`)) + " " + _toDisplayString(_ctx.$t(`transactionStatus.${transaction.status}`)), 1)
                        ]),
                        _: 2
                      }, 1024))
                ], 64))
          ])
        ]),
        (
          transaction.type === 'order' &&
            _ctx.isPendingTransactionStatus(transaction.status)
        )
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_BalBtn, {
                size: "xs",
                label: _ctx.$t('cancel'),
                loading: transaction.status === 'cancelling',
                "loading-label": _ctx.$t('cancelling'),
                color: "white",
                onClick: _withModifiers(($event: any) => (_ctx.cancelOrder(transaction.id)), ["prevent"])
              }, null, 8, ["label", "loading", "loading-label", "onClick"])
            ]))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}