import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "absolute top-0 left-0 w-full h-full bg-white dark:bg-gray-850 rounded-lg z-10 text-center" }
const _hoisted_2 = { class: "flex flex-col justify-between items-center h-full p-4" }
const _hoisted_3 = ["textContent"]
const _hoisted_4 = { class: "flex flex-col items-center" }
const _hoisted_5 = { class: "w-20 h-20 rounded-full flex items-center justify-center bg-green-100 dark:bg-green-500 text-green-500 dark:text-gray-850 mb-8" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "grid grid-cols-2 gap-4 mt-4 w-full" }
const _hoisted_8 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalBtn = _resolveComponent("BalBtn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", {
        textContent: _toDisplayString(_ctx.title)
      }, null, 8, _hoisted_3),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_BalIcon, {
            name: "check-circle",
            size: "xl"
          })
        ]),
        _createElementVNode("p", {
          innerHTML: _ctx.description,
          class: "text-gray-500"
        }, null, 8, _hoisted_6)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_BalBtn, {
          tag: "a",
          href: _ctx.explorerLink,
          target: "_blank",
          rel: "noreferrer",
          flat: "",
          color: "gray"
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", {
              textContent: _toDisplayString(_ctx.$t('receipt'))
            }, null, 8, _hoisted_8),
            _createVNode(_component_BalIcon, {
              name: "arrow-up-right",
              size: "sm",
              class: "ml-1"
            })
          ]),
          _: 1
        }, 8, ["href"]),
        _createVNode(_component_BalBtn, {
          label: _ctx.closeLabel,
          color: "gray",
          flat: "",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
        }, null, 8, ["label"])
      ])
    ])
  ]))
}