
import { computed, defineComponent, PropType } from 'vue';
import { chunk } from 'lodash';

import BalAsset from './BalAsset.vue';

export default defineComponent({
  components: {
    BalAsset
  },
  emits: ['click'],
  props: {
    addresses: {
      type: Array as PropType<string[]>,
      required: true
    },
    width: {
      type: Number,
      default: 200
    },
    size: {
      type: Number,
      default: 26
    },
    maxAssetsPerLine: {
      type: Number,
      default: 8
    },
    wrap: {
      type: Boolean
    }
  },

  setup(props) {
    /**
     * COMPUTED
     */
    const addressesChunks = computed(() =>
      chunk(props.addresses, props.maxAssetsPerLine)
    );

    const radius = computed(() => props.size / 2);

    const spacer = computed(
      () =>
        (props.maxAssetsPerLine / props.addresses.length - 1) *
        (radius.value * 2)
    );

    /**
     * METHODS
     */
    function leftOffsetFor(i: number) {
      if (props.wrap) return 0;
      return (
        ((props.width - radius.value * 2 + spacer.value) /
          (props.maxAssetsPerLine - 1)) *
        i
      );
    }

    return {
      // computed
      addressesChunks,

      // methods
      leftOffsetFor
    };
  }
});
