
import { defineComponent, ref } from 'vue';

export default defineComponent({
  emits: ['toggle'],
  setup(_props, { emit }) {
    const isRotated = ref(false);

    function toggle(): void {
      isRotated.value = !isRotated.value;
      emit('toggle');
    }

    return {
      isRotated,
      toggle
    };
  }
});
