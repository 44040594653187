import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WalletButton = _resolveComponent("WalletButton")!
  const _component_BalModal = _resolveComponent("BalModal")!

  return (_openBlock(), _createBlock(_component_BalModal, {
    show: _ctx.isVisible,
    onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
    title: "Connect to a wallet"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.wallets, (wallet) => {
        return (_openBlock(), _createBlock(_component_WalletButton, {
          wallet: wallet,
          key: wallet
        }, null, 8, ["wallet"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["show"]))
}