import { renderSlot as _renderSlot, withModifiers as _withModifiers, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["action", "method"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("form", _mergeProps({
    action: _ctx.action,
    method: _ctx.method
  }, _ctx.$attrs, {
    onSubmit: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('onSubmit')), ["prevent"]))
  }), [
    _renderSlot(_ctx.$slots, "default")
  ], 16, _hoisted_1))
}