import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-766abc7b")
const _hoisted_1 = { class: "relative" }
_popScopeId()

import { ref, computed, watch } from 'vue';

type PopoverTrigger = 'click' | 'hover';

type Props = {
  trigger?: PopoverTrigger;
  align?: string;
};


export default _defineComponent({
  props: {
    trigger: { type: String, required: false, default: 'click' },
    align: { type: String, required: false, default: 'right' }
  } as unknown as undefined,
  emits: ["show", "hide"] as unknown as undefined,
  setup(__props: {
  trigger?: PopoverTrigger;
  align?: string;
}, { emit }: { emit: ({
  (e: 'show'): void;
  (e: 'hide'): void;
}), expose: any, slots: any, attrs: any }) {

const props = __props




/**
 * STATE
 */
const popoverOpened = ref(false);

/**
 * COMPUTED
 */
const popoverWrapperClasses = computed(() => ({
  'bal-popover-wrapper-visible': popoverOpened.value,
  [`${props.align}-0`]: true
}));

watch(popoverOpened, () => {
  if (popoverOpened.value) {
    emit('show');
  } else {
    emit('hide');
  }
});

/**
 * METHODS
 */
function togglePopover() {
  popoverOpened.value = !popoverOpened.value;
}

function showPopover() {
  popoverOpened.value = true;
}

function hidePopover() {
  popoverOpened.value = false;
}

function handleClickOutside() {
  if (props.trigger === 'click') {
    hidePopover();
  }
}

return (_ctx: any,_cache: any) => {
  const _component_BalCard = _resolveComponent("BalCard")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "bal-popover-activator group",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (__props.trigger === 'click' && togglePopover())),
      onMouseenter: _cache[1] || (_cache[1] = ($event: any) => (__props.trigger === 'hover' && showPopover())),
      onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (__props.trigger === 'hover' && hidePopover()))
    }, [
      _renderSlot(_ctx.$slots, "activator")
    ], 32),
    _createElementVNode("div", {
      class: _normalizeClass(['bal-popover-wrapper', _unref(popoverWrapperClasses)])
    }, [
      _createVNode(_component_BalCard, _mergeProps({ shadow: "lg" }, _ctx.$attrs, {
        darkBgColor: "800",
        class: ""
      }), {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default", { close: hidePopover })
        ]),
        _: 3
      }, 16)
    ], 2)
  ], 512)), [
    [_directive_click_outside, handleClickOutside]
  ])
}
}

})