<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.6667 14H21L17.5 24.5L10.5 3.5L7 14H2.33334"
      stroke="url(#activity-icon-0)"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <defs>
      <linearGradient
        id="activity-icon-0"
        x1="14"
        y1="3.5"
        x2="14"
        y2="24.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#66FFB1" />
        <stop offset="1" stop-color="#10B981" />
      </linearGradient>
    </defs>
  </svg>
</template>
