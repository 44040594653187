import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createBlock as _createBlock, withScopeId as _withScopeId } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalAsset = _resolveComponent("BalAsset")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.addressesChunks, (addressesChunk, addressChunkIndex) => {
    return (_openBlock(), _createElementBlock("div", {
      key: addressChunkIndex,
      class: "addresses-row",
      style: _normalizeStyle({
        width: `${_ctx.width}px`,
        height: `${_ctx.size}px`
      })
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(addressesChunk, (address, i) => {
        return (_openBlock(), _createBlock(_component_BalAsset, {
          key: i,
          address: address,
          size: _ctx.size,
          onClick: ($event: any) => (_ctx.$emit('click', address)),
          class: _normalizeClass(['token-icon', { absolute: !_ctx.wrap, relative: _ctx.wrap }]),
          style: _normalizeStyle({
          left: `${_ctx.leftOffsetFor(i)}px`,
          zIndex: `${20 - i}`,
          width: `${_ctx.size}px`,
          height: `${_ctx.size}px`
        })
        }, null, 8, ["address", "size", "onClick", "class", "style"]))
      }), 128))
    ], 4))
  }), 128))
}