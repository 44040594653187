
import { computed, defineComponent, PropType } from 'vue';

import { Transaction } from '@/composables/useTransactions';
import useWeb3 from '@/services/web3/useWeb3';

export default defineComponent({
  name: 'ActivityRows',

  props: {
    transactions: {
      type: Array as PropType<Transaction[]>,
      required: true
    },
    getExplorerLink: {
      type: Function as PropType<
        (id: string, type: Transaction['type']) => void
      >,
      required: true
    },
    cancelOrder: {
      type: Function as PropType<(orderId: string) => void>
    },
    isSuccessfulTransaction: {
      type: Function as PropType<(transaction: Transaction) => boolean>,
      required: true
    },
    isPendingTransactionStatus: {
      type: Function as PropType<(transaction: Transaction) => boolean>,
      required: true
    }
  },

  setup() {
    /**
     * COMPOSABLES
     */
    const { connector } = useWeb3();

    /**
     * COMPUTED
     */
    const disablePending = computed(() => connector.value?.id === 'gnosis');

    return {
      // computed
      disablePending
    };
  }
});
