import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-4 w-full border-b dark:border-gray-900" }
const _hoisted_2 = { class: "-mt-2 p-4" }
const _hoisted_3 = { class: "mb-3" }
const _hoisted_4 = { class: "text-gray-500" }
const _hoisted_5 = { class: "font-semibold" }
const _hoisted_6 = { class: "mb-3" }
const _hoisted_7 = { class: "text-gray-500" }
const _hoisted_8 = { class: "font-semibold" }
const _hoisted_9 = { class: "text-gray-500" }
const _hoisted_10 = { class: "flex items-center font-semibold" }

import useNumbers, { FNumFormats } from '@/composables/useNumbers';

import { FullPool } from '@/services/balancer/subgraph/types';

import LiquidityAPRTooltip from '@/components/tooltips/LiquidityAPRTooltip.vue';
import { PoolMigrationInfo } from '@/components/forms/pool_actions/MigrateForm/types';

type Props = {
  poolMigrationInfo: PoolMigrationInfo;
  pool: FullPool;
};

/**
 * PROPS
 */

export default _defineComponent({
  props: {
    poolMigrationInfo: { type: null, required: true },
    pool: { type: null, required: true }
  } as unknown as undefined,
  setup(__props: {
  poolMigrationInfo: PoolMigrationInfo;
  pool: FullPool;
}) {



/**
 * COMPOSABLES
 */
const { fNum2 } = useNumbers();

return (_ctx: any,_cache: any) => {
  const _component_BalCard = _resolveComponent("BalCard")!

  return (_openBlock(), _createBlock(_component_BalCard, {
    noPad: "",
    shadow: "none"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h6", null, _toDisplayString(_ctx.$t(`migratePool.${__props.poolMigrationInfo.type}.poolStats.title`)), 1)
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('poolValue')), 1),
          _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(fNum2)(__props.pool.totalLiquidity, _unref(FNumFormats).fiat)), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('volumeTime', ['24h'])), 1),
          _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(fNum2)(__props.pool.dynamic.volume, _unref(FNumFormats).fiat)), 1)
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('apr')), 1),
          _createElementVNode("div", _hoisted_10, [
            _createTextVNode(_toDisplayString(_unref(fNum2)(__props.pool.dynamic.apr.total, _unref(FNumFormats).percent)) + " ", 1),
            _createVNode(LiquidityAPRTooltip, { pool: __props.pool }, null, 8, ["pool"])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}
}

})