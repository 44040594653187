import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "lineChartHeader",
  class: "mb-4"
}
const _hoisted_2 = { class: "text-gray-800 dark:text-gray-400 text-xl tracking-wider" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalLoadingBlock = _resolveComponent("BalLoadingBlock")!
  const _component_ECharts = _resolveComponent("ECharts")!

  return (_ctx.isLoading)
    ? (_openBlock(), _createBlock(_component_BalLoadingBlock, {
        key: 0,
        class: "h-96 mt-16"
      }))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: _normalizeClass([_ctx.wrapperClass]),
        onMouseleave: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleMouseLeave && _ctx.handleMouseLeave(...args)))
      }, [
        (_ctx.showHeader)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.currentValue), 1),
              _createElementVNode("span", {
                class: _normalizeClass({
          'text-green-400': _ctx.change >= 0,
          'text-red-400': _ctx.change < 0,
          'font-medium': true
        })
              }, _toDisplayString(_ctx.numeral(_ctx.change).format('+0.0%')), 3)
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_ECharts, {
          ref: "chartInstance",
          class: _normalizeClass([
        _ctx.height && typeof (_ctx.height === 'string') ? `h-${_ctx.height}` : '',
        'w-full',
        _ctx.chartClass
      ]),
          option: _ctx.chartConfig,
          autoresize: "",
          onUpdateAxisPointer: _ctx.handleAxisMoved,
          "update-options": { replaceMerge: 'series' },
          style: _normalizeStyle([_ctx.styleOverrides])
        }, null, 8, ["class", "option", "onUpdateAxisPointer", "style"])
      ], 34))
}