import { ACTIVE_EXPLOITS } from '@/constants/exploits';
import useWeb3 from '@/services/web3/useWeb3';
import { getAddress } from 'ethers/lib/utils';
import { watch } from 'vue';
import useAlerts, { AlertPriority, AlertType } from '../useAlerts';

export default function useExploitWatcher() {
  const { account } = useWeb3();
  const exploits = Object.keys(ACTIVE_EXPLOITS);
  const { addAlert } = useAlerts();

  function exploitCTA(link: string) {
    window.open(link, '_blank');
  }

  watch(account, () => {
    if (!account.value) return;
    for (const exploitedProtocol of exploits) {
      const exploit = ACTIVE_EXPLOITS[exploitedProtocol];
      // the list of wallets may not always be checksummed
      const checksummedWallets = exploit.wallets.map(wallet =>
        getAddress(wallet)
      );
      if (checksummedWallets.includes(getAddress(account.value))) {
        addAlert({
          id: exploitedProtocol,
          label: exploit.message,
          actionLabel: exploit.actionLabel,
          action: () => exploitCTA(exploit.link),
          type: AlertType.ERROR,
          priority: AlertPriority.HIGH
        });
      }
    }
  });
}
