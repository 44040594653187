import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

import { computed, toRefs } from 'vue';

import { FullPool } from '@/services/balancer/subgraph/types';

import usePoolQuery from '@/composables/queries/usePoolQuery';
import useTokens from '@/composables/useTokens';
import useRelayerApproval, {
  Relayer
} from '@/composables/trade/useRelayerApproval';

import Col3Layout from '@/components/layouts/Col3Layout.vue';

import MigrateExplainer from './components/MigrateExplainer.vue';
import PoolsInfo from './components/PoolsInfo/PoolsInfo.vue';
import PoolStats from './components/PoolStats.vue';

import { PoolMigrationInfo } from './types';

type Props = {
  poolMigrationInfo: PoolMigrationInfo;
};

/**
 * PROPS
 */

export default _defineComponent({
  props: {
    poolMigrationInfo: { type: null, required: true }
  } as unknown as undefined,
  setup(__props: {
  poolMigrationInfo: PoolMigrationInfo;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const { tokens } = useTokens();

/**
 * QUERIES
 */
const fromPoolQuery = usePoolQuery(props.poolMigrationInfo.fromPoolId);
const toPoolQuery = usePoolQuery(props.poolMigrationInfo.toPoolId);
const batchRelayerApproval = useRelayerApproval(Relayer.BATCH);

const { loading: batchRelayerApprovalLoading } = toRefs(batchRelayerApproval);

/**
 * COMPUTED
 */
const fromPoolLoading = computed(
  () => fromPoolQuery.isLoading.value || fromPoolQuery.isIdle.value
);

const toPoolLoading = computed(
  () => toPoolQuery.isLoading.value || toPoolQuery.isIdle.value
);

const isLoadingPools = computed(
  () => toPoolLoading.value || fromPoolLoading.value
);

const fromPool = computed<FullPool | undefined>(() => fromPoolQuery.data.value);

const toPool = computed<FullPool | undefined>(() => toPoolQuery.data.value);

const fromPoolTokenInfo = computed(() =>
  fromPool.value != null ? tokens.value[fromPool.value.address] : null
);

const toPoolTokenInfo = computed(() =>
  toPool.value != null ? tokens.value[toPool.value.address] : null
);

return (_ctx: any,_cache: any) => {
  const _component_BalLoadingBlock = _resolveComponent("BalLoadingBlock")!

  return (_openBlock(), _createBlock(Col3Layout, { offsetGutters: "" }, {
    gutterLeft: _withCtx(() => [
      _createVNode(MigrateExplainer, { poolMigrationInfo: __props.poolMigrationInfo }, null, 8, ["poolMigrationInfo"])
    ]),
    gutterRight: _withCtx(() => [
      (_unref(toPoolLoading))
        ? (_openBlock(), _createBlock(_component_BalLoadingBlock, {
            key: 0,
            class: "h-64"
          }))
        : (_openBlock(), _createBlock(PoolStats, {
            key: 1,
            pool: _unref(toPool),
            poolMigrationInfo: __props.poolMigrationInfo
          }, null, 8, ["pool", "poolMigrationInfo"]))
    ]),
    default: _withCtx(() => [
      (
        _unref(isLoadingPools) ||
          _unref(fromPoolTokenInfo) == null ||
          _unref(toPoolTokenInfo) == null ||
          _unref(batchRelayerApprovalLoading)
      )
        ? (_openBlock(), _createBlock(_component_BalLoadingBlock, {
            key: 0,
            class: "h-96"
          }))
        : (_openBlock(), _createBlock(PoolsInfo, {
            key: 1,
            fromPool: _unref(fromPool),
            toPool: _unref(toPool),
            fromPoolTokenInfo: _unref(fromPoolTokenInfo),
            toPoolTokenInfo: _unref(toPoolTokenInfo),
            poolMigrationInfo: __props.poolMigrationInfo
          }, null, 8, ["fromPool", "toPool", "fromPoolTokenInfo", "toPoolTokenInfo", "poolMigrationInfo"]))
    ]),
    _: 1
  }))
}
}

})