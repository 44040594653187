import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "list-disc pl-6" }

import { PoolMigrationInfo } from '../../../types';

/**
 * TYPES
 */
type Props = {
  poolMigrationInfo: PoolMigrationInfo;
};

/**
 * PROPS
 */

export default _defineComponent({
  props: {
    poolMigrationInfo: { type: null, required: true }
  } as unknown as undefined,
  setup(__props: {
  poolMigrationInfo: PoolMigrationInfo;
}) {



return (_ctx: any,_cache: any) => {
  const _component_BalAlert = _resolveComponent("BalAlert")!

  return (_openBlock(), _createBlock(_component_BalAlert, {
    type: "warning",
    title: _ctx.$t('migratePool.previewModal.riskWarnings.title'),
    block: "",
    class: "mb-4"
  }, {
    default: _withCtx(() => [
      _createElementVNode("ul", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.poolMigrationInfo.riskI18nLabels, (i18nLabel) => {
          return (_openBlock(), _createElementBlock("li", {
            class: "pt-2",
            key: i18nLabel
          }, _toDisplayString(_ctx.$t(i18nLabel)), 1))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}
}

})