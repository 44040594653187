import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pb-16" }

import { computed } from 'vue';
import { useRoute } from 'vue-router';
import AppNav from '@/components/navs/AppNav/AppNav.vue';
import AppHero from '@/components/heros/AppHero.vue';
import AppFooterNav from '@/components/navs/AppFooterNav/AppFooterNav.vue';
import useBreakpoints from '@/composables/useBreakpoints';

/**
 * COMPOSABLES
 */

export default _defineComponent({
  setup(__props) {

const route = useRoute();
const { upToLargeBreakpoint } = useBreakpoints();

/**
 * COMPUTED
 */
const isHomePage = computed(() => route.path === '/');

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(AppNav),
    (_unref(isHomePage))
      ? (_openBlock(), _createBlock(AppHero, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_view, {
        key: _ctx.$route.path
      })
    ]),
    (_unref(upToLargeBreakpoint))
      ? (_openBlock(), _createBlock(AppFooterNav, { key: 1 }))
      : _createCommentVNode("", true)
  ]))
}
}

})