import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = {
  key: 1,
  class: "border dark:border-gray-700 rounded-lg p-3 mt-4"
}

import { computed, ref, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';

import { FullPool } from '@/services/balancer/subgraph/types';

import { MigrateMathResponse } from '../../composables/useMigrateMath';

import MigratePoolRisks from './components/MigratePoolRisks.vue';
import MigratePoolsInfo from './components/MigratePoolsInfo.vue';
import MigrateActions from './components/MigrateActions.vue';
import InvestSummary from '../../../InvestForm/components/InvestPreviewModal/components/InvestSummary.vue';

import { TokenInfo } from '@/types/TokenList';

import { PoolMigrationInfo } from '../../types';

/**
 * TYPES
 */
type Props = {
  poolMigrationInfo: PoolMigrationInfo;
  fromPool: FullPool;
  toPool: FullPool;
  fromPoolTokenInfo: TokenInfo;
  toPoolTokenInfo: TokenInfo;
  math: MigrateMathResponse;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    poolMigrationInfo: { type: null, required: true },
    fromPool: { type: null, required: true },
    toPool: { type: null, required: true },
    fromPoolTokenInfo: { type: null, required: true },
    toPoolTokenInfo: { type: null, required: true },
    math: { type: null, required: true }
  } as unknown as undefined,
  emits: ["close"] as unknown as undefined,
  setup(__props: {
  poolMigrationInfo: PoolMigrationInfo;
  fromPool: FullPool;
  toPool: FullPool;
  fromPoolTokenInfo: TokenInfo;
  toPoolTokenInfo: TokenInfo;
  math: MigrateMathResponse;
}, { emit }: { emit: ({
  (e: 'close'): void;
}), expose: any, slots: any, attrs: any }) {

const props = __props




/**
 * STATE
 */
const {
  batchSwapLoaded,
  highPriceImpact,
  fiatTotal,
  fiatTotalLabel,
  priceImpact
} = toRefs(props.math);

const migrateConfirmed = ref(false);
const highPriceImpactAccepted = ref(false);

const hasAcceptedHighPriceImpact = computed((): boolean =>
  highPriceImpact.value ? highPriceImpactAccepted.value : true
);

const isLoadingPriceImpact = computed(() => !batchSwapLoaded.value);

/**
 * COMPOSABLES
 */
const { t } = useI18n();

/**
 * COMPUTED
 */
const title = computed((): string =>
  migrateConfirmed.value
    ? t('migratePool.previewModal.titles.confirmed')
    : t('migratePool.previewModal.titles.default')
);

/**
 * METHODS
 */
function handleClose() {
  emit('close');
}

return (_ctx: any,_cache: any) => {
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalCircle = _resolveComponent("BalCircle")!
  const _component_BalCheckbox = _resolveComponent("BalCheckbox")!
  const _component_BalModal = _resolveComponent("BalModal")!

  return (_openBlock(), _createBlock(_component_BalModal, {
    show: "",
    fireworks: migrateConfirmed.value,
    onClose: handleClose
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.withdrawalConfirmed)
          ? (_openBlock(), _createBlock(_component_BalCircle, {
              key: 0,
              size: "8",
              color: "green",
              class: "text-white mr-2"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BalIcon, { name: "check" })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createElementVNode("h4", null, _toDisplayString(_unref(title)), 1)
      ])
    ]),
    default: _withCtx(() => [
      (__props.poolMigrationInfo.riskI18nLabels != null)
        ? (_openBlock(), _createBlock(MigratePoolRisks, {
            key: 0,
            poolMigrationInfo: __props.poolMigrationInfo
          }, null, 8, ["poolMigrationInfo"]))
        : _createCommentVNode("", true),
      _createVNode(MigratePoolsInfo, {
        fromPoolTokenInfo: __props.fromPoolTokenInfo,
        toPoolTokenInfo: __props.toPoolTokenInfo
      }, null, 8, ["fromPoolTokenInfo", "toPoolTokenInfo"]),
      _createVNode(InvestSummary, {
        pool: __props.toPool,
        fiatTotal: _unref(fiatTotal),
        priceImpact: _unref(priceImpact),
        isLoadingPriceImpact: _unref(isLoadingPriceImpact),
        highPriceImpact: _unref(highPriceImpact)
      }, null, 8, ["pool", "fiatTotal", "priceImpact", "isLoadingPriceImpact", "highPriceImpact"]),
      (_unref(highPriceImpact))
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_BalCheckbox, {
              modelValue: highPriceImpactAccepted.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (highPriceImpactAccepted.value = $event)),
              name: "highPriceImpactAccepted",
              size: "sm",
              label: _ctx.$t('migratePool.previewModal.priceImpactAccept'),
              noMargin: ""
            }, null, 8, ["modelValue", "label"])
          ]))
        : _createCommentVNode("", true),
      _createVNode(MigrateActions, {
        fromPool: __props.fromPool,
        toPool: __props.toPool,
        fromPoolTokenInfo: __props.fromPoolTokenInfo,
        toPoolTokenInfo: __props.toPoolTokenInfo,
        fiatTotalLabel: _unref(fiatTotalLabel),
        math: __props.math,
        disabled: !_unref(batchSwapLoaded) || !_unref(hasAcceptedHighPriceImpact),
        onSuccess: _cache[1] || (_cache[1] = ($event: any) => (migrateConfirmed.value = true)),
        class: "mt-4"
      }, null, 8, ["fromPool", "toPool", "fromPoolTokenInfo", "toPoolTokenInfo", "fiatTotalLabel", "math", "disabled"])
    ]),
    _: 1
  }, 8, ["fireworks"]))
}
}

})