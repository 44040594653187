import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, isRef as _isRef, createElementVNode as _createElementVNode, Teleport as _Teleport } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "border dark:border-gray-700 rounded-lg p-2 pb-2 mt-4"
}
const _hoisted_2 = { class: "mt-4" }

import { toRef, computed, ref, nextTick, onBeforeMount, watch } from 'vue';
import { isRequired } from '@/lib/utils/validations';
import { bnum } from '@/lib/utils';
// Types
import { FullPool } from '@/services/balancer/subgraph/types';
// Composables
import { useI18n } from 'vue-i18n';
import useWeb3 from '@/services/web3/useWeb3';
import useTokens from '@/composables/useTokens';
import usePoolTransfers from '@/composables/contextual/pool-transfers/usePoolTransfers';
import useInvestState from './composables/useInvestState';
import useInvestMath from './composables/useInvestMath';
import { isStableLike, isStablePhantom, usePool } from '@/composables/usePool';
// Components
import TokenInput from '@/components/inputs/TokenInput/TokenInput.vue';
import InvestFormTotals from './components/InvestFormTotals.vue';
import InvestPreviewModal from './components/InvestPreviewModal/InvestPreviewModal.vue';
import WrapStEthLink from '@/components/contextual/pages/pool/invest/WrapStEthLink.vue';

/**
 * TYPES
 */
enum NativeAsset {
  wrapped = 'wrapped',
  unwrapped = 'unwrapped'
}

type Props = {
  pool: FullPool;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    pool: { type: null, required: true }
  } as unknown as undefined,
  setup(__props: {
  pool: FullPool;
}) {

const props = __props


/**
 * STATE
 */
const showInvestPreview = ref(false);

/**
 * COMPOSABLES
 */
const { t } = useI18n();
const { balanceFor, nativeAsset, wrappedNativeAsset } = useTokens();
const { useNativeAsset } = usePoolTransfers();
const {
  tokenAddresses,
  amounts,
  validInputs,
  highPriceImpactAccepted,
  resetAmounts,
  sor
} = useInvestState();

const investMath = useInvestMath(
  toRef(props, 'pool'),
  tokenAddresses,
  amounts,
  useNativeAsset,
  sor
);

const {
  hasAmounts,
  highPriceImpact,
  maximizeAmounts,
  optimizeAmounts,
  proportionalAmounts,
  batchSwapLoading
} = investMath;

const {
  isWalletReady,
  toggleWalletSelectModal,
  isMismatchedNetwork
} = useWeb3();

const { managedPoolWithTradingHalted, isWethPool, isStableLikePool } = usePool(
  toRef(props, 'pool')
);

/**
 * COMPUTED
 */
const hasValidInputs = computed(
  (): boolean =>
    validInputs.value.every(validInput => validInput === true) &&
    hasAcceptedHighPriceImpact.value
);

const hasAcceptedHighPriceImpact = computed((): boolean =>
  highPriceImpact.value ? highPriceImpactAccepted.value : true
);

const forceProportionalInputs = computed(
  (): boolean => managedPoolWithTradingHalted.value
);

const investmentTokens = computed((): string[] => {
  if (isStablePhantom(props.pool.poolType)) {
    return props.pool.mainTokens || [];
  }
  return props.pool.tokenAddresses;
});

/**
 * METHODS
 */
function handleAmountChange(value: string, index: number): void {
  amounts.value[index] = value;

  nextTick(() => {
    if (forceProportionalInputs.value) {
      amounts.value = [...proportionalAmounts.value];
    }
  });
}

function handleAddressChange(newAddress: string): void {
  useNativeAsset.value = newAddress === nativeAsset.address;
}

function tokenWeight(address: string): number {
  if (isStableLike(props.pool.poolType)) return 0;
  if (address === nativeAsset.address) {
    return props.pool.onchain.tokens[wrappedNativeAsset.value.address].weight;
  }

  return props.pool.onchain.tokens[address].weight;
}

function propAmountFor(index: number): string {
  if (isStableLikePool.value) return '0.0';

  return bnum(proportionalAmounts.value[index]).gt(0)
    ? proportionalAmounts.value[index]
    : '0.0';
}

function hint(index: number): string {
  return bnum(propAmountFor(index)).gt(0) ? t('proportionalSuggestion') : '';
}

function tokenOptions(index: number): string[] {
  return props.pool.tokenAddresses[index] === wrappedNativeAsset.value.address
    ? [wrappedNativeAsset.value.address, nativeAsset.address]
    : [];
}

// If ETH has a higher balance than WETH then use it for the input.
function setNativeAssetByBalance(): void {
  const nativeAssetBalance = balanceFor(nativeAsset.address);
  const wrappedNativeAssetBalance = balanceFor(
    wrappedNativeAsset.value.address
  );

  if (bnum(nativeAssetBalance).gt(wrappedNativeAssetBalance)) {
    setNativeAsset(NativeAsset.unwrapped);
    useNativeAsset.value = true;
  }
}

function setNativeAsset(to: NativeAsset): void {
  const fromAddress =
    to === NativeAsset.wrapped
      ? nativeAsset.address
      : wrappedNativeAsset.value.address;
  const toAddress =
    to === NativeAsset.wrapped
      ? wrappedNativeAsset.value.address
      : nativeAsset.address;

  const indexOfAsset = tokenAddresses.value.indexOf(fromAddress);

  if (indexOfAsset >= 0) {
    tokenAddresses.value[indexOfAsset] = toAddress;
  }
}

/**
 * CALLBACKS
 */
onBeforeMount(() => {
  resetAmounts();
  tokenAddresses.value = [...investmentTokens.value];
  if (isWethPool.value) setNativeAssetByBalance();
});

/**
 * WATCHERS
 */
watch(useNativeAsset, shouldUseNativeAsset => {
  if (shouldUseNativeAsset) {
    setNativeAsset(NativeAsset.unwrapped);
  } else {
    setNativeAsset(NativeAsset.wrapped);
  }
});

return (_ctx: any,_cache: any) => {
  const _component_BalAlert = _resolveComponent("BalAlert")!
  const _component_BalCheckbox = _resolveComponent("BalCheckbox")!
  const _component_BalBtn = _resolveComponent("BalBtn")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_unref(forceProportionalInputs))
      ? (_openBlock(), _createBlock(_component_BalAlert, {
          key: 0,
          type: "warning",
          title: _ctx.$t('investment.warning.managedPoolTradingHaulted.title'),
          description: 
        _ctx.$t('investment.warning.managedPoolTradingHaulted.description')
      ,
          class: "mb-4"
        }, null, 8, ["title", "description"]))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tokenAddresses).length, (n, i) => {
      return (_openBlock(), _createBlock(TokenInput, {
        key: i,
        name: _unref(tokenAddresses)[i],
        address: _unref(tokenAddresses)[i],
        "onUpdate:address": [
          ($event: any) => (_unref(tokenAddresses)[i] = $event),
          _cache[0] || (_cache[0] = ($event: any) => (handleAddressChange($event)))
        ],
        amount: _unref(amounts)[i],
        "onUpdate:amount": [($event: any) => (_unref(amounts)[i] = $event), ($event: any) => (handleAmountChange($event, i))],
        isValid: _unref(validInputs)[i],
        "onUpdate:isValid": ($event: any) => (_unref(validInputs)[i] = $event),
        weight: tokenWeight(_unref(tokenAddresses)[i]),
        hintAmount: propAmountFor(i),
        hint: hint(i),
        class: "mb-4",
        fixedToken: "",
        options: tokenOptions(i)
      }, null, 8, ["name", "address", "onUpdate:address", "amount", "onUpdate:amount", "isValid", "onUpdate:isValid", "weight", "hintAmount", "hint", "options"]))
    }), 128)),
    _createVNode(InvestFormTotals, {
      math: _unref(investMath),
      onMaximize: _unref(maximizeAmounts),
      onOptimize: _unref(optimizeAmounts)
    }, null, 8, ["math", "onMaximize", "onOptimize"]),
    (_unref(highPriceImpact))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_BalCheckbox, {
            modelValue: _unref(highPriceImpactAccepted),
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(highPriceImpactAccepted) ? highPriceImpactAccepted.value = $event : null)),
            rules: [_unref(isRequired)(_ctx.$t('priceImpactCheckbox'))],
            name: "highPriceImpactAccepted",
            size: "sm",
            label: _ctx.$t('priceImpactAccept', [_ctx.$t('depositing')])
          }, null, 8, ["modelValue", "rules", "label"])
        ]))
      : _createCommentVNode("", true),
    _createVNode(WrapStEthLink, {
      pool: __props.pool,
      class: "mt-4"
    }, null, 8, ["pool"]),
    _createElementVNode("div", _hoisted_2, [
      (!_unref(isWalletReady))
        ? (_openBlock(), _createBlock(_component_BalBtn, {
            key: 0,
            label: _ctx.$t('connectWallet'),
            color: "gradient",
            block: "",
            onClick: _unref(toggleWalletSelectModal)
          }, null, 8, ["label", "onClick"]))
        : (_openBlock(), _createBlock(_component_BalBtn, {
            key: 1,
            label: _ctx.$t('preview'),
            color: "gradient",
            disabled: 
          !_unref(hasAmounts) ||
            !_unref(hasValidInputs) ||
            _unref(isMismatchedNetwork) ||
            _unref(batchSwapLoading)
        ,
            block: "",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (showInvestPreview.value = true))
          }, null, 8, ["label", "disabled"]))
    ]),
    (_openBlock(), _createBlock(_Teleport, { to: "#modal" }, [
      (showInvestPreview.value)
        ? (_openBlock(), _createBlock(InvestPreviewModal, {
            key: 0,
            pool: __props.pool,
            math: _unref(investMath),
            tokenAddresses: _unref(tokenAddresses),
            onClose: _cache[3] || (_cache[3] = ($event: any) => (showInvestPreview.value = false))
          }, null, 8, ["pool", "math", "tokenAddresses"]))
        : _createCommentVNode("", true)
    ]))
  ]))
}
}

})