import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex" }
const _hoisted_2 = ["name", "checked"]
const _hoisted_3 = { class: "relative ml-2 flex-col" }
const _hoisted_4 = ["for"]
const _hoisted_5 = {
  key: 1,
  class: "bal-checkbox-error"
}
const _hoisted_6 = { class: "relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['bal-checkbox', _ctx.wrapperClasses])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _createElementVNode("input", {
          type: "checkbox",
          name: _ctx.name,
          checked: _ctx.modelValue,
          class: _normalizeClass(['bal-checkbox-input', _ctx.inputClasses]),
          onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args)))
        }, null, 42, _hoisted_2)
      ]),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.$slots.label || _ctx.label)
          ? (_openBlock(), _createElementBlock("label", {
              key: 0,
              for: _ctx.name,
              class: _normalizeClass(['bal-checkbox-label', _ctx.labelClasses])
            }, [
              _renderSlot(_ctx.$slots, "label", {}, () => [
                _createTextVNode(_toDisplayString(_ctx.label), 1)
              ])
            ], 10, _hoisted_4))
          : _createCommentVNode("", true),
        (_ctx.hasError)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.errors[0]), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 2))
}