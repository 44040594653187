import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "px-4 sm:px-0 flex justify-between items-end border-b dark:border-gray-900 mb-6" }

import { computed, ref, toRef } from 'vue';
import { useI18n } from 'vue-i18n';

import { FullPool } from '@/services/balancer/subgraph/types';

import { usePool } from '@/composables/usePool';

import Activities from './PoolActivities/Activities.vue';
import BoostedActivities from './BoostedPoolActivities/Activities.vue';
import Swaps from './PoolSwaps/Swaps.vue';

import { PoolTransactionsTab } from './types';

/**
 * TYPES
 */
type Props = {
  pool: FullPool;
  loading: boolean;
};

/**
 * PROPS
 */

export default _defineComponent({
  props: {
    pool: { type: null, required: true },
    loading: { type: Boolean, required: true, default: false }
  } as unknown as undefined,
  setup(__props: {
  pool: FullPool;
  loading: boolean;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const { isStablePhantomPool } = usePool(toRef(props, 'pool'));
const { t } = useI18n();

/**
 * COMPUTED
 */
const tabs = computed(() =>
  isStablePhantomPool.value
    ? [
        {
          value: PoolTransactionsTab.ALL_ACTIVITY,
          label: t('poolTransactions.tabs.allTransactions')
        },
        {
          value: PoolTransactionsTab.USER_ACTIVITY,
          label: t('poolTransactions.tabs.myTransactions')
        }
      ]
    : [
        {
          value: PoolTransactionsTab.ALL_ACTIVITY,
          label: t('poolTransactions.tabs.allInvestments')
        },
        {
          value: PoolTransactionsTab.SWAPS,
          label: t('poolTransactions.tabs.swaps')
        },
        {
          value: PoolTransactionsTab.USER_ACTIVITY,
          label: t('poolTransactions.tabs.myInvestments')
        }
      ]
);

/**
 * STATE
 */
const activeTab = ref(tabs.value[0].value);

return (_ctx: any,_cache: any) => {
  const _component_BalTabs = _resolveComponent("BalTabs")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_BalTabs, {
        modelValue: activeTab.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (activeTab.value = $event)),
        tabs: _unref(tabs),
        "no-pad": "",
        class: "-mb-px"
      }, null, 8, ["modelValue", "tabs"])
    ]),
    (_unref(isStablePhantomPool))
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (activeTab.value === _unref(PoolTransactionsTab).ALL_ACTIVITY)
            ? (_openBlock(), _createBlock(BoostedActivities, {
                key: 0,
                "pool-activity-type": _unref(PoolTransactionsTab).ALL_ACTIVITY,
                pool: __props.pool,
                loading: __props.loading
              }, null, 8, ["pool-activity-type", "pool", "loading"]))
            : (activeTab.value === _unref(PoolTransactionsTab).USER_ACTIVITY)
              ? (_openBlock(), _createBlock(BoostedActivities, {
                  key: 1,
                  "pool-activity-type": _unref(PoolTransactionsTab).USER_ACTIVITY,
                  pool: __props.pool,
                  loading: __props.loading
                }, null, 8, ["pool-activity-type", "pool", "loading"]))
              : _createCommentVNode("", true)
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (activeTab.value === _unref(PoolTransactionsTab).ALL_ACTIVITY)
            ? (_openBlock(), _createBlock(Activities, {
                key: 0,
                "pool-activity-type": _unref(PoolTransactionsTab).ALL_ACTIVITY,
                pool: __props.pool,
                loading: __props.loading
              }, null, 8, ["pool-activity-type", "pool", "loading"]))
            : (activeTab.value === _unref(PoolTransactionsTab).USER_ACTIVITY)
              ? (_openBlock(), _createBlock(Activities, {
                  key: 1,
                  "pool-activity-type": _unref(PoolTransactionsTab).USER_ACTIVITY,
                  pool: __props.pool,
                  loading: __props.loading
                }, null, 8, ["pool-activity-type", "pool", "loading"]))
              : (activeTab.value === _unref(PoolTransactionsTab).SWAPS)
                ? (_openBlock(), _createBlock(Swaps, {
                    key: 2,
                    pool: __props.pool,
                    loading: __props.loading
                  }, null, 8, ["pool", "loading"]))
                : _createCommentVNode("", true)
        ], 64))
  ], 64))
}
}

})