
import { defineComponent } from 'vue';
import AppIcon from './AppIcon.vue';

export default defineComponent({
  name: 'AppLogo',

  components: {
    AppIcon
  }
});
