import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 0,
  class: "px-6 py-4"
}
const _hoisted_5 = {
  key: 0,
  class: "px-6 py-4 flex items-center"
}
const _hoisted_6 = { class: "px-6 py-4 -mt-1 flex justify-end font-numeric" }
const _hoisted_7 = { class: "px-6 py-4 flex justify-end" }

import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

import {
  DecoratedPoolWithShares,
  PoolToken
} from '@/services/balancer/subgraph/types';

import { getAddress } from '@ethersproject/address';

import useNumbers, { FNumFormats } from '@/composables/useNumbers';
import useFathom from '@/composables/useFathom';
import useDarkMode from '@/composables/useDarkMode';
import useBreakpoints from '@/composables/useBreakpoints';
import {
  isStableLike,
  isStablePhantom,
  isMigratablePool
} from '@/composables/usePool';

import LiquidityAPRTooltip from '@/components/tooltips/LiquidityAPRTooltip.vue';
import { ColumnDefinition } from '@/components/_global/BalTable/BalTable.vue';
import { POOL_MIGRATIONS_MAP } from '@/components/forms/pool_actions/MigrateForm/constants';
import { PoolMigrationType } from '@/components/forms/pool_actions/MigrateForm/types';

import TokenPills from './TokenPills/TokenPills.vue';

/**
 * TYPES
 */
type Props = {
  data?: DecoratedPoolWithShares[];
  isLoading?: boolean;
  isLoadingMore?: boolean;
  showPoolShares?: boolean;
  noPoolsLabel?: string;
  isPaginated?: boolean;
  selectedTokens?: string[];
  showMigrationColumn?: boolean;
};

/**
 * PROPS & EMITS
 */


export default _defineComponent({
  props: {
    data: { type: Array, required: false },
    isLoading: { type: Boolean, required: false },
    isLoadingMore: { type: Boolean, required: false, default: false },
    showPoolShares: { type: Boolean, required: false, default: false },
    noPoolsLabel: { type: String, required: false, default: 'No pools' },
    isPaginated: { type: Boolean, required: false, default: false },
    selectedTokens: { type: Array, required: false },
    showMigrationColumn: { type: Boolean, required: false, default: false }
  } as unknown as undefined,
  emits: ['loadMore'],
  setup(__props: {
  data?: DecoratedPoolWithShares[];
  isLoading?: boolean;
  isLoadingMore?: boolean;
  showPoolShares?: boolean;
  noPoolsLabel?: string;
  isPaginated?: boolean;
  selectedTokens?: string[];
  showMigrationColumn?: boolean;
}, { emit }) {

const props = __props




/**
 * COMPOSABLES
 */
const { fNum2 } = useNumbers();
const router = useRouter();
const { t } = useI18n();
const { trackGoal, Goals } = useFathom();
const { darkMode } = useDarkMode();
const { upToLargeBreakpoint } = useBreakpoints();

/**
 * DATA
 */
const columns = ref<ColumnDefinition<DecoratedPoolWithShares>[]>([
  {
    name: 'Icons',
    id: 'icons',
    accessor: 'uri',
    Header: 'iconColumnHeader',
    Cell: 'iconColumnCell',
    width: 125,
    noGrow: true
  },
  {
    name: t('composition'),
    id: 'poolName',
    accessor: 'id',
    Cell: 'poolNameCell',
    width: 350
  },
  {
    name: t('myBalance'),
    accessor: pool =>
      fNum2(pool.shares, {
        style: 'currency',
        maximumFractionDigits: 0,
        fixedFormat: true
      }),
    align: 'right',
    id: 'myBalance',
    hidden: !props.showPoolShares,
    sortKey: pool => Number(pool.shares),
    width: 150,
    cellClassName: 'font-numeric'
  },
  {
    name: t('poolValue'),
    accessor: pool =>
      fNum2(pool.totalLiquidity, {
        style: 'currency',
        maximumFractionDigits: 0
      }),
    align: 'right',
    id: 'poolValue',
    sortKey: pool => {
      const apr = Number(pool.totalLiquidity);
      if (apr === Infinity || isNaN(apr)) return 0;
      return apr;
    },
    width: 150,
    cellClassName: 'font-numeric'
  },
  {
    name: t('volume24h', [t('hourAbbrev')]),
    accessor: pool =>
      fNum2(pool.dynamic.volume, {
        style: 'currency',
        maximumFractionDigits: 0
      }),
    align: 'right',
    id: 'poolVolume',
    sortKey: pool => {
      const apr = Number(pool.dynamic.volume);
      if (apr === Infinity || isNaN(apr)) return 0;
      return apr;
    },
    width: 175,
    cellClassName: 'font-numeric'
  },
  {
    name: t('apr'),
    Cell: 'aprCell',
    accessor: pool => pool.dynamic.apr.total,
    align: 'right',
    id: 'poolApr',
    sortKey: pool => {
      const apr = Number(pool.dynamic.apr.total);
      if (apr === Infinity || isNaN(apr)) return 0;
      return apr;
    },
    width: 150
  },
  {
    name: t('migrate'),
    Cell: 'migrateCell',
    accessor: 'migrate',
    align: 'right',
    id: 'migrate',
    width: 150,
    hidden: !props.showMigrationColumn
  }
]);

/**
 * METHODS
 */
function orderedTokenAddressesFor(pool: DecoratedPoolWithShares) {
  const sortedTokens = orderedPoolTokens(pool);
  return sortedTokens.map(token => getAddress(token.address));
}

function orderedPoolTokens(pool: DecoratedPoolWithShares): PoolToken[] {
  if (isStablePhantom(pool.poolType))
    return pool.tokens.filter(token => token.address !== pool.address);
  if (isStableLike(pool.poolType)) return pool.tokens;

  const sortedTokens = pool.tokens.slice();
  sortedTokens.sort((a, b) => parseFloat(b.weight) - parseFloat(a.weight));
  return sortedTokens;
}

function handleRowClick(pool: DecoratedPoolWithShares) {
  trackGoal(Goals.ClickPoolsTableRow);
  router.push({ name: 'pool', params: { id: pool.id } });
}

function navigateToPoolMigration(pool: DecoratedPoolWithShares) {
  router.push({
    name: 'migrate-pool',
    params: {
      from: pool.id,
      to: POOL_MIGRATIONS_MAP[PoolMigrationType.AAVE_BOOSTED_POOL].toPoolId
    },
    query: { returnRoute: 'home' }
  });
}

return (_ctx: any,_cache: any) => {
  const _component_BalAssetSet = _resolveComponent("BalAssetSet")!
  const _component_BalChip = _resolveComponent("BalChip")!
  const _component_BalBtn = _resolveComponent("BalBtn")!
  const _component_BalTable = _resolveComponent("BalTable")!
  const _component_BalCard = _resolveComponent("BalCard")!

  return (_openBlock(), _createBlock(_component_BalCard, {
    shadow: "lg",
    class: "mt-4",
    square: _unref(upToLargeBreakpoint),
    noBorder: _unref(upToLargeBreakpoint),
    noPad: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BalTable, {
        columns: columns.value,
        data: __props.data,
        "is-loading": __props.isLoading,
        "is-loading-more": __props.isLoadingMore,
        "skeleton-class": "h-64",
        sticky: "both",
        square: _unref(upToLargeBreakpoint),
        link: {
        to: 'pool',
        getParams: pool => ({ id: pool.id })
      },
        "on-row-click": handleRowClick,
        "is-paginated": __props.isPaginated,
        onLoadMore: _cache[0] || (_cache[0] = ($event: any) => (emit('loadMore'))),
        "initial-state": {
        sortColumn: 'poolValue',
        sortDirection: 'desc'
      }
      }, {
        iconColumnHeader: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_unref(darkMode))
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: require('@/assets/images/icons/tokens_white.svg')
                }, null, 8, _hoisted_2))
              : (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  src: require('@/assets/images/icons/tokens_black.svg')
                }, null, 8, _hoisted_3))
          ])
        ]),
        iconColumnCell: _withCtx((pool) => [
          (!__props.isLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_BalAssetSet, {
                  addresses: orderedTokenAddressesFor(pool),
                  width: 100
                }, null, 8, ["addresses"])
              ]))
            : _createCommentVNode("", true)
        ]),
        poolNameCell: _withCtx((pool) => [
          (!__props.isLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(TokenPills, {
                  tokens: orderedPoolTokens(pool),
                  isStablePool: _unref(isStableLike)(pool.poolType),
                  selectedTokens: __props.selectedTokens
                }, null, 8, ["tokens", "isStablePool", "selectedTokens"]),
                (pool.dynamic.isNewPool)
                  ? (_openBlock(), _createBlock(_component_BalChip, {
                      key: 0,
                      color: "red",
                      size: "sm",
                      class: "ml-2 uppercase",
                      outline: false
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('new')), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]),
        aprCell: _withCtx((pool) => [
          _createElementVNode("div", _hoisted_6, [
            _createTextVNode(_toDisplayString(Number(pool.dynamic.apr.pool) > 10000
              ? '-'
              : _unref(fNum2)(pool.dynamic.apr.total, _unref(FNumFormats).percent)) + " ", 1),
            _createVNode(LiquidityAPRTooltip, { pool: pool }, null, 8, ["pool"])
          ])
        ]),
        migrateCell: _withCtx((pool) => [
          _createElementVNode("div", _hoisted_7, [
            (_unref(isMigratablePool)(pool))
              ? (_openBlock(), _createBlock(_component_BalBtn, {
                  key: 0,
                  color: "gradient",
                  size: "sm",
                  onClick: _withModifiers(($event: any) => (navigateToPoolMigration(pool)), ["prevent"])
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('migrate')), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }, 8, ["columns", "data", "is-loading", "is-loading-more", "square", "link", "is-paginated"])
    ]),
    _: 1
  }, 8, ["square", "noBorder"]))
}
}

})