
import useBreakpoints from '@/composables/useBreakpoints';
import useDarkMode from '@/composables/useDarkMode';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'DarkModeToggle',

  setup() {
    // COMPOSABLES
    const { upToLargeBreakpoint } = useBreakpoints();
    const { darkMode, toggleDarkMode } = useDarkMode();

    return { darkMode, toggleDarkMode, upToLargeBreakpoint };
  }
});
