import { Multicaller } from '@/lib/utils/balancer/contract';
import { configService } from '@/services/config/config.service';
import { rpcProviderService } from '@/services/rpc-provider/rpc-provider.service';
import { utils } from 'ethers';
import { Pool } from '../../types';

const acsiFarmAddress = '0x0C3B6058c25205345b8f22578B27065a7506671C';
const acsiFarmAbi = [
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'user', type: 'address' },
      {
        indexed: true,
        internalType: 'address',
        name: 'lpToken',
        type: 'address'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256'
      }
    ],
    name: 'Deposit',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'previousOwner',
        type: 'address'
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'newOwner',
        type: 'address'
      }
    ],
    name: 'OwnershipTransferred',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'user', type: 'address' },
      {
        indexed: true,
        internalType: 'address',
        name: 'lpToken',
        type: 'address'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256'
      }
    ],
    name: 'Withdraw',
    type: 'event'
  },
  {
    inputs: [],
    name: 'REWARD_DENOMINATOR',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: '_lpToken', type: 'address' },
      {
        components: [
          { internalType: 'address', name: 'rewardToken', type: 'address' },
          { internalType: 'address', name: 'from', type: 'address' },
          { internalType: 'uint256', name: 'rewardPerBlock', type: 'uint256' }
        ],
        internalType: 'struct ACryptoSFarmV3.AdditionalPoolReward[]',
        name: '_additionalPoolRewards',
        type: 'tuple[]'
      }
    ],
    name: 'addAdditionalPoolRewards',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'address', name: 'to', type: 'address' },
          { internalType: 'uint256', name: 'reward', type: 'uint256' }
        ],
        internalType: 'struct ACryptoSFarmV3.AdditionalReward[]',
        name: '_additionalRewards',
        type: 'tuple[]'
      }
    ],
    name: 'addAdditionalRewards',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'uint256', name: '', type: 'uint256' }
    ],
    name: 'additionalPoolRewards',
    outputs: [
      { internalType: 'address', name: 'rewardToken', type: 'address' },
      { internalType: 'address', name: 'from', type: 'address' },
      { internalType: 'uint256', name: 'rewardPerBlock', type: 'uint256' }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: '_lpToken', type: 'address' }],
    name: 'additionalPoolRewardsLength',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    name: 'additionalRewards',
    outputs: [
      { internalType: 'address', name: 'to', type: 'address' },
      { internalType: 'uint256', name: 'reward', type: 'uint256' }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'boostFactor',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'boostToken',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: '_lpToken', type: 'address' },
      { internalType: 'address', name: '_user', type: 'address' }
    ],
    name: 'calculateWeight',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: '_lpToken', type: 'address' }],
    name: 'deleteAdditionalPoolRewards',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [],
    name: 'deleteAdditionalRewards',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: '_lpToken', type: 'address' },
      { internalType: 'uint256', name: '_amount', type: 'uint256' }
    ],
    name: 'deposit',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: '_lpToken', type: 'address' }],
    name: 'harvest',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [],
    name: 'harvestFee',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'harvestFeeAddress',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'maxBoost',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: '_lpToken', type: 'address' },
      { internalType: 'address', name: '_user', type: 'address' }
    ],
    name: 'pendingSushi',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'poolInfo',
    outputs: [
      { internalType: 'uint256', name: 'totalWeight', type: 'uint256' },
      { internalType: 'uint256', name: 'allocPoint', type: 'uint256' },
      { internalType: 'uint256', name: 'lastRewardBlock', type: 'uint256' },
      { internalType: 'uint256', name: 'accSushiPerShare', type: 'uint256' },
      { internalType: 'uint256', name: 'withdrawalFee', type: 'uint256' }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: '_lpToken', type: 'address' },
      { internalType: 'uint256', name: '_allocPoint', type: 'uint256' },
      { internalType: 'uint256', name: '_withdrawalFee', type: 'uint256' }
    ],
    name: 'set',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'uint256', name: '_boostFactor', type: 'uint256' }
    ],
    name: 'setBoostFactor',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'uint256', name: '_harvestFee', type: 'uint256' }],
    name: 'setHarvestFee',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: '_harvestFeeAddress', type: 'address' }
    ],
    name: 'setHarvestFeeAddress',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'uint256', name: '_maxBoost', type: 'uint256' }],
    name: 'setMaxBoost',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: '_strategist', type: 'address' }],
    name: 'setStrategist',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'uint256', name: '_sushiPerBlock', type: 'uint256' }
    ],
    name: 'setSushiPerBlock',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [],
    name: 'strategist',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'sushi',
    outputs: [
      { internalType: 'contract ERC20Mintable', name: '', type: 'address' }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'sushiPerBlock',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'totalAllocPoint',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: '_lpToken', type: 'address' }],
    name: 'updatePool',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'address', name: '', type: 'address' }
    ],
    name: 'userInfo',
    outputs: [
      { internalType: 'uint256', name: 'amount', type: 'uint256' },
      { internalType: 'uint256', name: 'weight', type: 'uint256' },
      { internalType: 'uint256', name: 'rewardDebt', type: 'uint256' },
      { internalType: 'uint256', name: 'rewardCredit', type: 'uint256' }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: '_lpToken', type: 'address' },
      { internalType: 'uint256', name: '_amount', type: 'uint256' }
    ],
    name: 'withdraw',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  }
];

type PoolId = string;

type LiquidityMiningTokenRewards = {
  tokenAddress: string;
  amount: number;
};

type LiquidityMiningPools = Record<PoolId, LiquidityMiningTokenRewards[]>;

export const currentFarmRewards: LiquidityMiningPools = {};
// {
//   "0xa6f548df93de924d73be7d25dc02554c6bd66db500020000000000000000000e": [
//     {
//       "tokenAddress": "0xba100000625a3754423978a60c9317c58a424e3d",
//       "amount": 2500
//     }
//   ],
//   "0x5c6ee304399dbdb9c8ef030ab642b10820db8f56000200000000000000000014": [
//     {
//       "tokenAddress": "0xba100000625a3754423978a60c9317c58a424e3d",
//       "amount": 2500
//     }
//   ],
//   "0x0b09dea16768f0799065c475be02919503cb2a3500020000000000000000001a": [
//     {
//       "tokenAddress": "0xba100000625a3754423978a60c9317c58a424e3d",
//       "amount": 2500
//     }
//   ]
// }

export async function getFarmRewards(pools: Pool[]) {
  const farmMultiCaller = new Multicaller(
    configService.network.key,
    rpcProviderService.jsonProvider,
    acsiFarmAbi
  );

  farmMultiCaller.call('sushi', acsiFarmAddress, 'sushi');
  farmMultiCaller.call('sushiPerBlock', acsiFarmAddress, 'sushiPerBlock');
  farmMultiCaller.call('totalAllocPoint', acsiFarmAddress, 'totalAllocPoint');
  pools.forEach(pool => {
    const poolAddress = pool.id.slice(0, 42);
    // console.log(poolAddress);
    farmMultiCaller.call(poolAddress, acsiFarmAddress, 'poolInfo', [
      poolAddress
    ]);
  });

  const results = await farmMultiCaller.execute();

  // console.log(results);
  pools.forEach(pool => {
    const poolAddress = pool.id.slice(0, 42);
    currentFarmRewards[pool.id] = [
      {
        tokenAddress: results.sushi,
        amount: Number(
          utils.formatEther(
            results.sushiPerBlock
              .mul(28800 * 7) // 1 week's rewards
              .mul(results[poolAddress].allocPoint)
              .div(results.totalAllocPoint)
          )
        )
      }
    ];
  });
  // console.log(currentFarmRewards);
}
