
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ActivityCounter',

  props: {
    count: {
      type: Number,
      required: true
    }
  }
});
