
import { ColumnDefinition } from '@/components/_global/BalTable/BalTable.vue';
import { TokenTotal, WeeklyDistributions } from '@/pages/liquidity-mining.vue';
import TokenPills from '../PoolsTable/TokenPills/TokenPills.vue';
import {
  DecoratedPoolWithShares,
  PoolToken
} from '@/services/balancer/subgraph/types';
import { getAddress } from '@ethersproject/address';
import { computed, defineComponent, PropType, Ref, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import useTokens from '@/composables/useTokens';
import useNumbers from '@/composables/useNumbers';
import { last, sum } from 'lodash';
import useDarkMode from '@/composables/useDarkMode';
import { isStableLike, isStablePhantom } from '@/composables/usePool';
import { startOfWeek, subWeeks, format, addDays } from 'date-fns';

function getWeekName(week: string) {
  const parts = week.split('_');
  return `Week ${parts[1]}`;
}

export default defineComponent({
  components: {
    TokenPills
  },
  props: {
    weeks: {
      type: Object as PropType<WeeklyDistributions[]>,
      required: true
    },
    poolMetadata: {
      type: Object
    },
    isLoading: {
      type: Boolean
    },
    totals: {
      type: Object as PropType<Ref<Record<string, TokenTotal[]>>>,
      required: true
    }
  },
  setup(props) {
    const { t } = useI18n();
    const { weeks, poolMetadata } = toRefs(props);
    const { tokens, priceFor } = useTokens();
    const { fNum2 } = useNumbers();
    const { darkMode } = useDarkMode();

    const data = computed(() => {
      if (!poolMetadata.value) return [];
      return poolMetadata.value[0].pools.map(pool => ({
        address: pool.address,
        tokens: pool.tokens,
        distributions: weeks.value.map(week => ({
          week: week.week,
          distribution: week.distributions[pool.id.toLowerCase()]
        })),
        poolType: pool.poolType,
        id: pool.id
      }));
    });

    const columns = computed<ColumnDefinition[]>(() => {
      return [
        {
          name: 'Icons',
          id: 'icons',
          accessor: 'uri',
          Header: 'iconColumnHeader',
          Cell: 'iconColumnCell',
          width: 125,
          noGrow: true
        },
        {
          name: t('composition'),
          id: 'poolName',
          accessor: 'id',
          Cell: 'poolNameCell',
          width: 350
        },
        ...weeks.value.map(({ week }, i) => ({
          name: getWeekName(week),
          accessor: week,
          id: week,
          Cell: week,
          Header: `header-${week}`,
          width: 135,
          align: 'right' as any,
          sortKey: pool => {
            return sum(
              (pool.distributions[i].distribution || []).map(d => d.amount)
            );
          },
          totalsCell: `totals-${week}`
        }))
      ];
    });

    const latestWeek = computed(() => last(weeks.value)?.week);

    function orderedPoolTokens(pool: DecoratedPoolWithShares): PoolToken[] {
      if (isStablePhantom(pool.poolType))
        return pool.tokens.filter(token => token.address !== pool.address);
      if (isStableLike(pool.poolType)) return pool.tokens;

      const sortedTokens = pool.tokens.slice();
      sortedTokens.sort((a, b) => parseFloat(b.weight) - parseFloat(a.weight));
      return sortedTokens;
    }

    function orderedTokenAddressesFor(pool: DecoratedPoolWithShares) {
      const sortedTokens = orderedPoolTokens(pool);
      return sortedTokens.map(token => getAddress(token.address));
    }

    function calculatePricesFor(totals: TokenTotal[]) {
      let totalFiat = 0;
      for (const total of totals) {
        const usdValue = priceFor(getAddress(total.token)) * total.total;
        totalFiat = totalFiat + usdValue;
      }
      return totalFiat;
    }

    function getWeekStart(howManyWeeksToSubtract: number) {
      return format(
        // startOfWeek is Sunday for date-fns
        addDays(startOfWeek(subWeeks(new Date(), howManyWeeksToSubtract)), 1),
        'dd/MM/yyyy'
      );
    }

    return {
      orderedTokenAddressesFor,
      orderedPoolTokens,
      fNum2,
      getAddress,
      calculatePricesFor,
      isStableLike,
      getWeekName,
      columns,
      data,
      tokens,
      priceFor,
      darkMode,
      latestWeek,
      getWeekStart
    };
  }
});
